import { contactTypes } from '.';

const initialState = {
  data: [],
  plans: [],
  companies: [],
  verticalsOptions: [],
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case contactTypes.SAVE_CONTACT:
      return payload;

    case contactTypes.RESET_CONTACT:
      return {
        ...state,
        data: [],
        loading: true,
      };

    case contactTypes.GET_CONTACT:
      return {
        ...state,
        data: [],
        loading: true,
      };

    case contactTypes.GET_CONTACT_SUCCESS:
      return {
        ...state,
        data: payload.contacts,
        plans: payload.plans,
        companies: payload.companies,
        verticalsOptions: payload.verticals,
        loading: false,
      };

    case contactTypes.GET_DATA_SUCCESS:
      return {
        ...state,
        data: {
          business_role: null,
          company: '0',
          email: null,
          first_name: '',
          last_name: '',
          phone: '',
          plan: '0',
          is_active: '0',
          notes: '',
          content_settings: {},
          verticals: [],
        },
        plans: payload.plans,
        companies: payload.companies,
        verticalsOptions: payload.verticals,
        loading: false,
      };

    // case contactTypes.SET_CONTACT:
    //   return {
    //     ...state,
    //     data: [],
    //     loading: true,
    //   };

    // case contactTypes.SET_CONTACT_SUCCESS:
    //   return {
    //     ...state,
    //     // data: payload,
    //     loading: false,
    //   };

    case contactTypes.REMOVE_CONTACT:
      return state.filter((alert) => alert.id !== payload);

    case contactTypes.ERROR:
      return {
        ...state,
        data: [],
        loading: false,
      };

    default:
      return state;
  }
}
