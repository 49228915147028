import React from 'react';

const Logo = () => (
  <svg
    id="prefix__Camada_1"
    x={0}
    y={0}
    viewBox="0 0 78.402 30.705"
    xmlSpace="preserve"
  >
    <style>{'.prefix__st0{fill:none}.prefix__st1{fill:#e84b37}'}</style>
    <path
      className="prefix__st0"
      d="M35.573 8.012a10.608 10.608 0 00-3.313-2.24 10.106 10.106 0 00-4.032-.816c-1.852 0-3.599.472-5.191 1.405a10.347 10.347 0 00-3.787 3.806 10.291 10.291 0 00-1.386 5.21c0 1.865.467 3.621 1.386 5.22a10.32 10.32 0 003.787 3.816 10.14 10.14 0 005.19 1.404c1.852 0 3.599-.472 5.191-1.404a10.334 10.334 0 003.787-3.816c.92-1.6 1.387-3.357 1.387-5.22 0-1.423-.27-2.783-.798-4.043a10.337 10.337 0 00-2.22-3.322z"
    />
    <path
      className="prefix__st0"
      d="M36.73 6.884a12.145 12.145 0 00-3.851-2.617 11.66 11.66 0 00-4.652-.938c-2.172 0-4.177.537-6.013 1.61a11.947 11.947 0 00-4.375 4.404 11.865 11.865 0 00-1.62 6.054c0 2.172.54 4.187 1.62 6.043a11.97 11.97 0 004.375 4.395c1.836 1.073 3.84 1.61 6.013 1.61 1.646 0 3.19-.31 4.632-.929a12.176 12.176 0 003.841-2.607 11.952 11.952 0 003.516-8.532c0-1.646-.303-3.193-.908-4.642a11.96 11.96 0 00-2.578-3.85zm1.726 12.785a10.995 10.995 0 01-2.379 3.577 11.39 11.39 0 01-3.566 2.407c-1.353.584-2.794.88-4.283.88-2.016 0-3.89-.501-5.57-1.49a11.087 11.087 0 01-4.021-4.06c-.987-1.704-1.488-3.59-1.488-5.606 0-2.015.5-3.895 1.489-5.588a11.194 11.194 0 014.02-4.049c1.681-.995 3.555-1.5 5.57-1.5 1.488 0 2.929.29 4.283.861a11.063 11.063 0 013.567 2.398 11.132 11.132 0 012.377 3.576 11.12 11.12 0 01.851 4.302c0 1.487-.286 2.931-.85 4.292zm24.127-1.863h8.414L66.79 6.47l-4.207 11.337zm6.32-.935l-2.113-5.768-2.093 5.797h-.834l2.927-7.87 2.896 7.84h-.784z"
    />
    <path
      className="prefix__st1"
      d="M10.46 19.377c0 1.225-.081 2.253-.24 3.056a7.829 7.829 0 01-.854 2.308c-.7 1.24-1.726 2.244-3.05 2.987a8.681 8.681 0 01-4.304 1.12c-.254 0-.516-.013-.712-.026l-.108-.007v.74l.094.005c.545.036.69.048.726.048a9.403 9.403 0 004.423-1.086c1.37-.721 2.468-1.697 3.265-2.899a8.07 8.07 0 001.182-2.66c.216-.924.324-2.13.324-3.586V1.907h-.747v17.47z"
    />
    <path
      className="prefix__st1"
      d="M1.855 27.938c1.316 0 2.55-.3 3.703-.898 1.152-.6 2.064-1.419 2.736-2.46.474-.71.803-1.47.987-2.28.184-.81.277-1.873.277-3.19V1.907H7.523v17.618c0 1.211-.148 2.218-.444 3.021a4.913 4.913 0 01-1.472 2.074c-.487.421-1.105.764-1.856 1.027a6.87 6.87 0 01-2.291.395h-.297v1.876c.198 0 .34.004.425.01.086.007.174.01.267.01zM36.078 7.5a11.063 11.063 0 00-3.567-2.4 10.962 10.962 0 00-4.283-.86c-2.015 0-3.889.504-5.57 1.499a11.194 11.194 0 00-4.02 4.05c-.988 1.692-1.489 3.572-1.489 5.587 0 2.015.5 3.902 1.488 5.607a11.087 11.087 0 004.022 4.06c1.68.988 3.553 1.489 5.569 1.489 1.489 0 2.93-.296 4.283-.88a11.39 11.39 0 003.566-2.407 10.995 10.995 0 002.38-3.577c.563-1.36.85-2.805.85-4.292 0-1.5-.287-2.947-.852-4.302A11.132 11.132 0 0036.078 7.5zm1.127 13.097a10.334 10.334 0 01-3.787 3.816 10.146 10.146 0 01-5.19 1.404c-1.853 0-3.6-.472-5.191-1.404a10.32 10.32 0 01-3.787-3.816 10.342 10.342 0 01-1.386-5.22c0-1.864.467-3.618 1.386-5.21a10.347 10.347 0 013.787-3.806 10.141 10.141 0 015.19-1.405c1.423 0 2.78.275 4.033.816a10.608 10.608 0 013.313 2.24 10.337 10.337 0 013.019 7.365c0 1.863-.467 3.62-1.387 5.22z"
    />
    <path
      className="prefix__st1"
      d="M35.259 3.26c-2.16-1.257-4.504-1.887-7.032-1.887-2.527 0-4.872.63-7.03 1.887a13.938 13.938 0 00-5.116 5.105c-1.251 2.147-1.877 4.484-1.877 7.012 0 2.541.626 4.885 1.877 7.031s2.956 3.848 5.115 5.106c2.16 1.257 4.504 1.886 7.031 1.886 2.528 0 4.872-.629 7.032-1.886a13.936 13.936 0 005.115-5.106c1.251-2.146 1.876-4.49 1.876-7.031 0-2.528-.625-4.865-1.876-7.012a13.937 13.937 0 00-5.115-5.105zm4.039 16.778a11.952 11.952 0 01-2.598 3.87 12.176 12.176 0 01-3.841 2.608c-1.442.619-2.986.929-4.632.929-2.172 0-4.177-.537-6.013-1.61a11.97 11.97 0 01-4.375-4.395 11.813 11.813 0 01-1.62-6.043c0-2.173.54-4.19 1.62-6.054a11.947 11.947 0 014.375-4.404c1.836-1.073 3.84-1.61 6.013-1.61 1.646 0 3.197.313 4.652.938a12.145 12.145 0 013.85 2.617 11.96 11.96 0 012.579 3.851c.605 1.449.908 2.996.908 4.642 0 1.646-.306 3.2-.918 4.66zM58.62 1.907H42.445v1.896h6.478v25.063h2.015V3.803h7.682z"
    />
    <path
      className="prefix__st1"
      d="M58.63 4.694h-6.79v24.172h.751V5.426h6.04zm-16.13 0h5.556v.733H42.5zM70.902 20.58H62.62l-3.063 8.282h.842l2.757-7.53h7.273l2.723 7.53h.833l-3.063-8.222zm-7.039-3.68h.834l2.093-5.797 2.112 5.768h.784L66.79 9.03z"
    />
    <path
      className="prefix__st1"
      d="M66.79 1.097l-10.448 27.77h2.153l3.357-9.165h9.875l3.358 9.164h2.153L66.79 1.097zm-4.207 16.71L66.79 6.468l4.207 11.337h-8.414z"
    />
  </svg>
);

export default Logo;
