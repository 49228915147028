import React from "react";

function ClipIconDisabled() {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99996 4.49999V11.5533C6.99996 12.9467 5.97996 14.1867 4.59329 14.32C2.99996 14.4733 1.66663 13.2267 1.66663 11.6667V3.42665C1.66663 2.55332 2.29329 1.75999 3.15996 1.67332C4.15996 1.57332 4.99996 2.35332 4.99996 3.33332V10.3333C4.99996 10.7 4.69996 11 4.33329 11C3.96663 11 3.66663 10.7 3.66663 10.3333V4.49999C3.66663 4.22665 3.43996 3.99999 3.16663 3.99999C2.89329 3.99999 2.66663 4.22665 2.66663 4.49999V10.24C2.66663 11.1133 3.29329 11.9067 4.15996 11.9933C5.15996 12.0933 5.99996 11.3133 5.99996 10.3333V3.44665C5.99996 2.05332 4.97996 0.813319 3.59329 0.679985C2.00663 0.526652 0.666626 1.77332 0.666626 3.33332V11.5133C0.666626 13.4267 2.06663 15.14 3.97329 15.32C6.16663 15.52 7.99996 13.8133 7.99996 11.6667V4.49999C7.99996 4.22665 7.77329 3.99999 7.49996 3.99999C7.22663 3.99999 6.99996 4.22665 6.99996 4.49999Z"
        fill="#a4a4a6"
      />
    </svg>
  );
}

export default ClipIconDisabled;
