import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AlertList from './alertList/AlertList';
import AlertCreate from './alert/AlertCreate';
import AlertEdit from './alert/AlertEdit';
import ContactList from './contactList/ContactList';
import ContactCreate from './contact/ContactCreate';
import ContactEdit from './contact/ContactEdit';
import Login from './login';

import { isAuthenticated } from '../api/auth';

const Routes = () => {
  const authRole = (Component) => (isAuthenticated() ? <Component /> : <Redirect to="/" />);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/dashboard" render={() => authRole(AlertList)} />
        <Route exact path="/alert/add" render={() => authRole(AlertCreate)} />
        <Route exact path="/alert/edit/:id" render={() => authRole(AlertEdit)} />
        <Route exact path="/contact" render={() => authRole(ContactList)} />
        <Route exact path="/contact/add" render={() => authRole(ContactCreate)} />
        <Route exact path="/contact/edit/:id" render={() => authRole(ContactEdit)} />
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
