import styled from "styled-components";

const COLOR_GRAY = "#d6d5da";
const COLOR_BLACK = "#000000";

export const Title = styled.h3`
  margin: 40px auto 10px;
  max-width: 855px;
  border-bottom: 1px solid red;
`;
export const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 70px auto 20px;
  font: lighter 16px/20px "Roboto", sans-serif;
  .center {
    width: 100%;
    margin: auto;
    max-width: 860px;
    background-color: #fff;
    border: 1px solid #dbdad3;
    border-radius: 5px;
    padding: 0 30px 40px;
    box-sizing: border-box;

    h3 {
      color: #b3210c;
      font-size: 22px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    .box-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }
  }
`;

export const JotaHeader = styled.div`
  margin-top: 18px;
  padding-top: 12px;
`;
export const JotaTitle = styled.h1`
  margin: 0;
  padding: 0;
  color: var(--black-jota-black, #000);

  /* Body/jotaBodyLarge */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
`;
export const JotaDescription = styled.p`
  color: var(--gray-jota-gray-dark, #73757f);

  /* Body/jotaBodySmall */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0;
  padding: 0;
`;

export const MainAttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

`;

export const ItemField = styled.div`
  &  {
    margin: 12px 0;

    & > input, & > select{
      margin-top: 4px;
    }
  }
`;
export const JotaLabel = styled.span`
  color: #646d78;

  /* Body/jotaOverlineSmall */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.12px;
  text-transform: uppercase;
  display: block;

  & > span {
    color: red;
  }
`;

export const GenerateFilesContainer = styled.div``;

export const Input = styled.input`
  width: 100%;
  border: 1px solid #dbdad3;
  border-radius: 6px;
  background-color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  color: #444;
  font: 400 19px/28px "Roboto", sans-serif;
  outline: none;
`;

export const InputFile = styled.input`
  display: none;
`;

export const AddFilesButton = styled.button`
  background-color: ${(props) => (props.disabled ? COLOR_GRAY : "#0089fa")};
  border: none;
  height: 34px;
  box-sizing: border-box;
  border-radius: 0.3125rem;
  color: ${(props) => (props.disabled ? "#00000045" : "#fff")};
  cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font: 500 17px Roboto, sans-serif;
  outline: none;
  margin-top: 10px;
`;

export const AddFilesButtonDisabled = styled.button`
  background-color: ${COLOR_GRAY};
  border: none;
  height: 34px;
  box-sizing: border-box;
  border-radius: 0.3125rem;
  color: #a4a4a6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font: 500 17px Roboto, sans-serif;
  outline: none;
  margin-top: 10px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FileButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  button {
    font-size: 11px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    border: none;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 3px;
    display: flex;
    gap: 5px;
    align-items: center;
    color: #fb4a3e;
    background: transparent;
  }

  button:first-child {
    fill: ${COLOR_BLACK};
    color: ${COLOR_BLACK};
    background: transparent;
  }

  button:nth-child(3) {
    fill: #fb4a3e;
  }

  @media (max-width: 500px) {
    button > span {
      display: none;
    }

    button svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const EditTagButton = styled.button`
  background: ${(props) =>
    props.isVisible ? "black !important" : "transparent !important"};
  color: ${(props) =>
    props.isVisible ? "white !important" : "black !important"};

  &::after {
    content: "*";
    color: #fb4a3e;
  }

  @media (max-width: 500px) {
    & {
      border-radius: 50% !important;
    }

    span {
      display: none;
    }

    &::after {
      display: none;
    }
  }
`;

export const FileNameOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646d78;
  font-weight: bold;
  background-color: #f5f6f8;
  border: 1px solid #e7e8ec;
  border-radius: 4px 4px 0px 0px;
  padding: 7px 12px;
`;

export const FileTagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  position: relative;
  width: 100%;
`;

export const SaveTagButton = styled.button`
  background: #d6d6da;
  color: #a3a3a6;
  padding: 16px 8px !important;
  margin-right: 12px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  width: 80px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: bold;

  ${(props) =>
    !props.disabled &&
    `background-color: #1B9C23;
      color: white;
    `}
`;

export const Label = styled.label`
  font: 400 18px/24px "Roboto", sans-serif;
  padding: 20px 0 10px;
  width: 100%;
  float: left;
  span {
    font: 400 16px/20px "Roboto", sans-serif;
    color: #b3210c;
    font-style: italic;
    margin-left: 4px;
  }
`;

export const CheckboxLabel = styled.label`
  margin-top: 20px;
  display: block;
`;

export const Button = styled.button`
  width: 150px;
  height: 34px;
  margin: 10px 0 0 15px;
  box-sizing: border-box;
  background: transparent;
  display: block;
  border-radius: 0.3125rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font: 500 17px Roboto, sans-serif;
  outline: none;
  &.save {
    background: #489e06;
    border: 1px solid #489e06;
  }
  &.cancel {
    background: #e84b37;
    border: 1px solid #e84b37;
  }

  &:disabled{
    opacity: .5;
  }

  &:disabled:hover{
    cursor: inherit;
  }
`;
