import {
  getContacts,
  activeContact,
  inactiveContact,
  deleteContact,
} from "../../api";

export const contactTypes = {
  LOAD: "LOAD_CONTACTS",
  LOAD_SUCCESS: "LOAD_CONTACTS_SUCCESS",
  LOAD_FAILED: "LOAD_CONTACTS_FAILED",

  RESET_CONTACTS: "RESET_CONTACTS",

  ACTIVE: "ACTIVE_CONTACT",
  ACTIVE_FAILED: "ACTIVE_CONTACT_FAILED",
  ACTIVE_SUCCESS: "ACTIVE_CONTACT_SUCCESS",

  DELETE: "DELETE_CONTACT",
  DELETE_FAILED: "DELETE_CONTACT_FAILED",
  DELETE_SUCCESS: "DELETE_CONTACT_SUCCESS",

  INACTIVE: "INACTIVE_CONTACT",
  INACTIVE_FAILED: "INACTIVE_CONTACT_FAILED",
  INACTIVE_SUCCESS: "INACTIVE_CONTACT_SUCCESS",

  UPDATE: "UPDATE_ALERT",

  LOAD_CONTACTS_BY_SEARCH: "LOAD_CONTACTS_BY_SEARCH",
};

export const assetsAlertsType = {
  DELETE: "DELETE_ASSET",
};

export const resetContactList = () => async (dispatch) => {
  dispatch({
    type: contactTypes.RESET_CONTACTS,
  });
};

export const loadContactList = (page, type, data) => async (dispatch) => {
  dispatch({
    type: contactTypes.LOAD,
  });

  try {
    const contactLit = await getContacts(page, type);
    dispatch({
      type: contactTypes.LOAD_SUCCESS,
      payload: contactLit,
    });
  } catch (error) {
    const msg = !type
      ? "Erro ao carregar os alertas!"
      : type === "published"
      ? "Não há alertas publicados!"
      : "Não há alertas não publicados!";

    dispatch({
      type: contactTypes.LOAD_FAILED,
      payload: { message: msg },
    });
  }
};

export const setActiveContact = (id, data) => async (dispatch) => {
  try {
    await activeContact(id);
    dispatch({
      type: contactTypes.ACTIVE_SUCCESS,
      payload: data.map((e) => {
        return e.id === id ? Object.assign(e, { is_active: true }) : e;
      }),
    });

    dispatch({
      type: "HIDE_POPUP",
    });
  } catch (error) {
    dispatch({
      type: contactTypes.ACTIVE_FAILED,
      payload: { message: "Erro ao publicar o alerta" },
    });
  }
};

export const setInctiveContact = (id, data) => async (dispatch) => {
  try {
    await inactiveContact(id);
    dispatch({
      type: contactTypes.INACTIVE_SUCCESS,
      payload: data.map((e) => {
        return e.id === id ? Object.assign(e, { is_active: false }) : e;
      }),
    });
    dispatch({
      type: "HIDE_POPUP",
    });
  } catch (error) {
    dispatch({
      type: contactTypes.INACTIVE_FAILED,
      payload: { message: "Erro ao cancelar publicação do alerta" },
    });
  }
};

export const sendDeleteContact = (id, data) => async (dispatch) => {
  try {
    await deleteContact(id);
    dispatch({
      type: contactTypes.DELETE_SUCCESS,
      payload: data.filter((e) => e.id !== id),
    });
    dispatch({
      type: "HIDE_POPUP",
    });
  } catch (error) {
    dispatch({
      type: contactTypes.DELETE_FAILED,
      payload: { message: "Erro ao cancelar publicação do alerta" },
    });
  }
};

export const loadContactBySearch = (data) => async (dispatch) => {
  try {
    dispatch({
      type: contactTypes.LOAD_CONTACTS_BY_SEARCH,
      payload: data,
    });
  } catch (error) {
    console.log("ERROR REDUX", error, data);
  }
};

export const deleteAsset = (data) => async (dispatch) => {
  try {
    dispatch({
      type: assetsAlertsType.DELETE,
      payload: data,
    });
  } catch (error) {
    console.log("ERROR REDUX", error, data);
  }
};
