import React from "react";

function LinkIcon() {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3334 0.666687H8.33337C7.96671 0.666687 7.66671 0.966687 7.66671 1.33335C7.66671 1.70002 7.96671 2.00002 8.33337 2.00002H10.3334C11.4334 2.00002 12.3334 2.90002 12.3334 4.00002C12.3334 5.10002 11.4334 6.00002 10.3334 6.00002H8.33337C7.96671 6.00002 7.66671 6.30002 7.66671 6.66669C7.66671 7.03335 7.96671 7.33335 8.33337 7.33335H10.3334C12.1734 7.33335 13.6667 5.84002 13.6667 4.00002C13.6667 2.16002 12.1734 0.666687 10.3334 0.666687ZM4.33337 4.00002C4.33337 4.36669 4.63337 4.66669 5.00004 4.66669H9.00004C9.36671 4.66669 9.66671 4.36669 9.66671 4.00002C9.66671 3.63335 9.36671 3.33335 9.00004 3.33335H5.00004C4.63337 3.33335 4.33337 3.63335 4.33337 4.00002ZM5.66671 6.00002H3.66671C2.56671 6.00002 1.66671 5.10002 1.66671 4.00002C1.66671 2.90002 2.56671 2.00002 3.66671 2.00002H5.66671C6.03337 2.00002 6.33337 1.70002 6.33337 1.33335C6.33337 0.966687 6.03337 0.666687 5.66671 0.666687H3.66671C1.82671 0.666687 0.333374 2.16002 0.333374 4.00002C0.333374 5.84002 1.82671 7.33335 3.66671 7.33335H5.66671C6.03337 7.33335 6.33337 7.03335 6.33337 6.66669C6.33337 6.30002 6.03337 6.00002 5.66671 6.00002Z"
        fill="black"
      />
    </svg>
  );
}

export default LinkIcon;
