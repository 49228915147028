import styled from "styled-components";
import { connect } from "react-redux";
import {
  GenerateFilesContainer,
  FilesContainer,
  FileNameOptions,
  FileButtonsContainer,
  EditTagButton,
  FileTagsContainer,
  SaveTagButton,
  AddFilesButton,
  InputFile,
  MainAttachmentContainer,
  ItemField,
  JotaLabel,
  Input,
  JotaHeader,
  JotaTitle,
  JotaDescription,
} from "./../../components/alert/Alert.style";
import {
  addAssetStore,
  deleteAssetStore,
  updateAssetStore,
  updateUITag,
  updateShortURL,
  updateTag,
  addPreCommitFiles,
  removePreCommitFiles,
  updateMainAttach,
  updateMainText,
  btnSaveToogle,
} from "../../store/alert";
import { generateUID, customStylesTags } from "./utils";
import LinkIcon from "../../assets/LinkIcon.svg";
import Select from "react-select";
import TagIcon from "../../assets/TagIcon.svg";
import TrashCanIcon from "../../assets/TrashCanIcon.svg";
import { useState, useEffect, useRef } from "react";
import { getAssetTags, getAssetURLs, uploadFile, deleteFile } from "../../api";
import ClipIconDisabled from "../../assets/ClipIconDisabled.svg";
import JotaAlert from "./../utils/jotaAlert.component";
import { POST, PATCH, NOTHING, WAIT_FOR_SAVE } from "./contants";

const DEFAULT_MAIN_TEXT = "Confira a íntegra:";

/**
 * Remove File Logic
 */
export const removeFile = (item, alertData = false, click_confirm = false) => {
  return new Promise(async (resolve, reject) => {
    // check if has tags that it's necessary rollback
    if (item?.tag?.old) {
      await getAssetURLs(item.tag.old, item.id_file, PATCH);
    }

    // new content?
    if (
      (item.isNew === true && alertData?.status !== "publish") ||
      (item.isNew === true && alertData.status === undefined) ||
      (click_confirm && alertData.status !== "publish")
    ) {
      // is uploaded?
      if (item.id_file) {
        deleteFile(item.id_file)
          .then(() => resolve(item.id))
          .catch((error) => reject(error));
      } else {
        resolve(item.id);
      }
    } else {
      resolve(item);
    }
  });
};

const FileManager = ({
  assets,
  alert,
  isPublished,
  updateUITag,
  updateShortURL,
  updateTag,
  addPreCommitFiles,
  alertState,
  removePreCommitFiles,
  alertData,
  updateMainAttach,
  updateMainText,
  btnStatus,
  btnSaveToogle,
}) => {
  const [assetsState, setAssetsState] = useState([]);
  const [tagOptions, setOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [isPublishState, setIsPublishedState] = useState(false);
  const [dialog, setDialog] = useState({
    title: "",
    description: "",
    type: "",
    show: false,
    functionCancel: null,
    successFn: null,
  });
  const [listAssets, setListAssets] = useState([]);
  const [mainAttach, setMainAttach] = useState({
    text: DEFAULT_MAIN_TEXT,
    main: { value: "", label: "" },
  });
  const [btnSave, setBtnSave] = useState(true);
  const mainAttchTextRef = useRef(null);
  const mainAttachSelectRef = useRef(null);

  useEffect(() => {
    updateOptions();
  }, []);

  useEffect(() => {
    setAssetsState(assets);
    setIsPublishedState(isPublished);

    // unsubscribe
    return () => {
      setAssetsState([]);
      setIsPublishedState(false);
    };
  }, [assets, isPublished]);

  /**
   * UseEffect to monitor Assets
   * Make a list on select
   */
  useEffect(() => {
    if (alertState.preCommit.length > 0) {
      const tempAssetSelectList = alertState.preCommit
        .filter(
          (asset) =>
            (asset.id_file && asset?.name?.includes(".pdf")) ||
            (asset.id_file && asset?.filename?.includes(".pdf"))
        )
        .map((asset) => ({
          value: asset.id,
          label: asset.name ?? asset.filename,
        }));

      const mainAttachTemp = alertState.preCommit.filter(
        (asset) => asset.leading || asset.mainAttach
      )[0];

      if (mainAttachTemp) {
        setMainAttach({
          text: mainAttachTemp?.label ?? DEFAULT_MAIN_TEXT,
          main: {
            label: mainAttachTemp?.filename || mainAttachTemp.name,
            value: mainAttachTemp?.id_file,
          },
        });
        if (mainAttchTextRef.current) {
          mainAttchTextRef.current.value =
            mainAttachTemp?.label ?? DEFAULT_MAIN_TEXT;
        }
      } else {
        setMainAttach({
          text: DEFAULT_MAIN_TEXT,
          main: { value: "", label: "" },
        });

        if (mainAttchTextRef.current) {
          mainAttchTextRef.current.value = DEFAULT_MAIN_TEXT;
        }

        if (mainAttachSelectRef.current) {
          mainAttachSelectRef.current.value = {};
        }
      }

      // add only assets with ID tag (after saved)
      if (tempAssetSelectList.length > 0) {
        setListAssets(tempAssetSelectList);
      }
    } else {
      setListAssets([]);
      setMainAttach({
        text: DEFAULT_MAIN_TEXT,
        main: { value: "", label: "" },
      });
    }

    return () => {
      setListAssets([]);
      setMainAttach({
        text: DEFAULT_MAIN_TEXT,
        main: { value: "", label: "" },
      });
    };
  }, [alertState.preCommit]);

  /**
   * Update Tags Select
   */
  const updateOptions = () => {
    getAssetTags()
      .then((results) => {
        setOptions(results);
      })
      .catch((error) => {
        window.alert(error);
      });
  };

  const containsSpecialChars = (str) => {
    const specialChars = /[`!@#$%^&*()+=\[\]{};'"\\|,<>\/?~]/;
    return specialChars.test(str);
  };

  /**
   * Handle Add Attachment
   * @param {event} e
   * @param {boolean} isUploaded
   */
  const handleAddAttachment = (e, isUploaded = false) => {
    const idFile = generateUID();
    const fileTemp = { id: idFile, file: e.target.files[0] };

    if (fileTemp.file.name.length > 120) {
      window.alert("O nome do arquivo não pode ter mais de 120 caracteres");
      return;
    }

    if (containsSpecialChars(fileTemp.file.name)) {
      window.alert("O nome do arquivo contém caracteres especiais ou espaços");
      return;
    }
    const file = {
      name: fileTemp.file.name,
      size: fileTemp.file.size,
      type: fileTemp.file.type,
      isUploaded: isUploaded,
      id: idFile,
      isNew:
        alertData.status === "unpublished" || alertData.status === undefined,
    };

    try {
      addPreCommitFiles(file);
      setFiles([...files, fileTemp]);
    } catch (error) {
      window.alert(
        "Erro ao adicionar anexo, tente novamente ou entre em contato com o time",
        error
      );
    }
  };

  /**
   * Get File by Id
   * @param {string} id
   * @return {File}
   */
  const getFileById = (id) => {
    return files.filter((item) => item.id === id && item.file);
  };

  /**
   * Handle Tag - Select Change
   */
  const handleTagSelectChange = (asset, selectedOption) => {
    const newTag = {
      label: selectedOption.label,
      value: selectedOption.value,
      isSaved: false,
      old: asset.identifier ? asset.tag : null,
    };

    updateTag(asset.id, newTag);
  };

  /**
   * Handle Save Tag Event
   * @parm {astype} asset
   */
  const handleSaveTag = (e, asset) => {
    e.preventDefault();
    updateTag(asset.id, asset.tag);

    if (typeof asset.tag === "object") {
      btnSaveToogle(false);
      asset.tag.isSaved = true;

      const get_verb = asset.identifier && asset.id !== null ? PATCH : POST;
      const id_asset =
        asset.identifier && asset.id !== null ? asset.id_file : asset.id;

      const file_upload = {
        tag: asset.tag.value,
        filename: asset.name,
      };

      getAssetURLs(file_upload, id_asset, get_verb)
        .then((response) => {
          const data_upload = response.data;

          updateShortURL(response.data, asset.id);

          if (get_verb !== PATCH) {
            /**
             * Upload to S3 Bucket
             */
            uploadFile(data_upload.upload_url, getFileById(asset.id))
              .then((response) => {
                console.log("upload final", response);
                btnSaveToogle(true);
              })
              .catch((error) => {
                window.alert(error);
                btnSaveToogle(true);
                console.error("upload error", error);
              });
          }

          btnSaveToogle(true);
        })
        .catch((error) => {
          window.alert(error);
          btnSaveToogle(true);
        });
    }
  };

  /**
   * Remove Asset
   * @param {e} Event
   * @param {string} id asset
   */
  const removeAsset = (e, item) => {
    e.preventDefault();
    setDialog({
      ...dialog,
      title: "Deseja excluir o anexo?",
      description:
        "Tem certeza que deseja excluir? Essa operação não poderá ser desfeita!",
      show: true,
      type: "confirm",
      successFn: async () => {
        setDialog({
          ...dialog,
          show: true,
          title: "Removendo Anexo",
          description: WAIT_FOR_SAVE,
          type: NOTHING,
        });
        removeFile(item, alertData.status, true).then((id) => {
          removePreCommitFiles(id);
          setDialog({
            ...dialog,
            show: false,
          });
        });
      },
      cancelFn: () => {
        setDialog({
          ...dialog,
          show: false,
        });
      },
    });
  };

  /**
   * Get Label from ID
   * @param {string} id
   * @param {string} tagOptions
   * @return {string}
   */
  const getLabelfromId = (id, tagOptions) => {
    let tag = null;
    tagOptions.forEach((item) => {
      if (item.value === id) {
        tag = item.label;
      }
    });
    return tag;
  };

  /**
   * Get Filename
   * @param {string} file
   */
  const getFilename = (file) => {
    const LIMIT_CHAT = 60;
    if (file.length > LIMIT_CHAT) {
      return file.substring(0, LIMIT_CHAT) + "...";
    }
    return file;
  };

  return (
    <FileManagerContainer>
      <JotaAlert
        title={dialog.title}
        description={dialog.description}
        type={dialog.type}
        show={dialog.show}
        functionCancel={() => dialog.cancelFn()}
        functionParent={(e) => dialog.successFn(e)}
      />

      <JotaHeader>
        <JotaTitle>Arquivos Anexos</JotaTitle>
        <JotaDescription>
          Preferencialmente arquivos mp3, mp4, pdf ou png
        </JotaDescription>
      </JotaHeader>

      <GenerateFilesContainer>
        {alertState.preCommit.map((asset) => (
          <FilesContainer
            key={generateUID()}
            id={`fileContainer-${asset.name}`}
            isVisible={alert}
          >
            <FileNameOptions>
              <span id={`fileName-${asset.name ?? asset.filename}`}>
                {getFilename(asset.name ?? asset.filename)}
              </span>
              <FileButtonsContainer>
                {asset.short_url && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(asset.short_url);
                      window.alert("Link copiado com sucesso.");
                    }}
                  >
                    <LinkIcon />
                    <span> Copiar Link </span>
                  </button>
                )}
                <EditTagButton
                  onClick={() => updateUITag(asset)}
                  id={`editTagButton-${asset.name}`}
                  isVisible={asset.showTagInput}
                >
                  <TagIcon
                    id={`tagIcon-${asset.name}`}
                    filled={asset.showTagInput}
                  />
                  <span> Editar Tag </span>
                </EditTagButton>
                {!isPublishState && (
                  <button onClick={(e) => removeAsset(e, asset)}>
                    <TrashCanIcon />
                    <span>Excluir </span>
                  </button>
                )}
              </FileButtonsContainer>
            </FileNameOptions>
            <FileTagsContainer
              id={`tagsContainer-${asset.filename}`}
              style={{
                display: asset.showTagInput ? "flex" : "none",
                border:
                  asset?.tag?.isSaved === false ? "2px solid red" : "inherit",
                borderRadius: asset?.tag?.isSaved === false ? "7px" : "inherit",
              }}
            >
              {tagOptions && (
                <Select
                  id={`tagSelect-${asset.filename}`}
                  isSingle
                  isSearchable
                  options={tagOptions}
                  value={
                    typeof asset.tag === "object"
                      ? asset?.tag
                      : {
                          value: asset.tag,
                          label: getLabelfromId(asset.tag, tagOptions),
                        }
                  }
                  onChange={(selectedOption) =>
                    handleTagSelectChange(asset, selectedOption)
                  }
                  styles={customStylesTags}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Selecione uma tag"
                />
              )}

              <SaveTagButton
                id={`saveTagButton-${asset.filename}`}
                disabled={asset?.tag?.isSaved}
                onClick={(e) => handleSaveTag(e, asset)}
              >
                <span>Salvar </span>
              </SaveTagButton>
            </FileTagsContainer>
          </FilesContainer>
        ))}
      </GenerateFilesContainer>

      {listAssets.length > 0 && (
        <MainAttachmentContainer>
          <ItemField>
            <JotaLabel>Anexo Principal</JotaLabel>
            <Select
              id="verticals"
              isSingle
              isSearchable
              ref={mainAttachSelectRef}
              className="basic-multi-select"
              value={mainAttach.main ?? {}}
              classNamePrefix="select"
              onInputChange={(e) => e}
              onChange={(e) => {
                updateMainAttach(e);
              }}
              options={listAssets}
            />
          </ItemField>

          <ItemField>
            <JotaLabel>
              Texto para o anexo principal<span>*</span>
            </JotaLabel>
            <Input
              type="text"
              id="tags"
              name="tags"
              required={true}
              ref={mainAttchTextRef}
              defaultValue={mainAttach.text ?? DEFAULT_MAIN_TEXT}
              onBlur={(e) => updateMainText(e.target.value)}
            />
          </ItemField>
        </MainAttachmentContainer>
      )}

      <AddFilesButton
        disabled={isPublishState}
        id="importFile"
        value={`Adicionar novo anexo`}
        onClick={(e) => {
          e.preventDefault();
          document.getElementById("file").click();
        }}
      >
        <ClipIconDisabled />
        Adicionar novo anexo
      </AddFilesButton>

      <InputFile
        type="file"
        id="file"
        name="file"
        value=""
        onChange={(e) => {
          handleAddAttachment(e);
        }}
      />
    </FileManagerContainer>
  );
};

const FileManagerContainer = styled.div``;

const mapStateToProps = (state) => ({
  auth: state.auth,
  alertData: state.alert.data,
  alertAsset: state.alert.assets,
  state: state,
  alertState: state.alert,
  btnStatus: state.alert.btnSaveEnable,
});

export default connect(mapStateToProps, {
  addAssetStore,
  deleteAssetStore,
  updateAssetStore,
  updateUITag,
  updateShortURL,
  updateTag,
  addPreCommitFiles,
  removePreCommitFiles,
  updateMainAttach,
  updateMainText,
  btnSaveToogle,
})(FileManager);
