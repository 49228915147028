// @ts-nocheck
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Title, Main, Input, Button, Label, CheckboxLabel } from "./Alert.style";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import Loading from "../layout/Loading";
import {
  saveAlert,
  setAlert,
  addAssetStore,
  deleteAssetStore,
  updateAssetStore,
  updateUITag,
  updateShortURL,
  updateTag,
  cleanAlert,
  removePreCommitFiles,
  clearRedux,
} from "../../store/alert";
import { showPopUp } from "../../store/popup";
import { customStyles } from "../utils/utils";
import {
  EDITOR_CONFIG,
  PUBLISHED,
  WAIT_FOR_SAVE,
  NOTHING,
  DASHBOARD,
} from "../utils/contants";
import JotaAlert from "./../utils/jotaAlert.component";
import JotaStateUI from "../utils/stateUI.component";
import FileManager, { removeFile } from "../utils/FileManager.compoonent";
import { createAlert, updateMainAsset } from "./../../api";

const EDITOR_API = process.env.REACT_APP_EDITOR_API;

class FormAlert extends Component {
  state = {
    flagStar: false,
    loading: false,
    redirect: null,
    flagPlans: false,
    flagContent: false,
    alert: {},
    alertOriginal: {},
    content: "",
    editor: "",
    fileName: "",
    verticals: [],
    tags: [],
    isTagContainerVisible: [],
    editTagIndex: null,
    tagOptions: [],
    selectedTagId: null,
    files: [],
    dialog: {
      show: false,
      title: null,
      description: null,
      type: null,
      successFn: null,
      cancelFn: null,
    }
  };

  componentDidMount() {
    this.props.clearRedux();

    if (this.props.alert && this.props.alert.verticals) {
      this.setState({ 
        verticals: this.props.alert.verticals,
        custom_delivery: this.props.alert.custom_delivery
       });
    }    
    
  }

  componentWillReceiveProps() {
    this.updateRedux();
  }

  /**
   * Update Redux
   */
  async updateRedux() {
    const valueEditor =
      (await this.props.alertData) && this.props.alertData.content;

    this.setState({
      ...this.state,
      content: valueEditor,    
      verticals: this.props.alert.verticals || this.state.verticals,
      custom_delivery: this.props.alert.custom_delivery || this.state.custom_delivery
    });
  }

  /**
   * Handle Change Verticals
   * @param {event} e
   */
  handleChangeVerticals(e) {
    this.setState({
      verticals: e
    })
  }

  handledCustomDelivery(e) {
    this.setState({
      custom_delivery: e.target.checked
    });          
    this.handleChangeVerticals(this.props.alert.verticalsOptions);    
  }

  /**
   * Has tags unsaved?
   */
  hasTagsUnsaved = () => {
    let output = null;
    this.props.preCommit.forEach((item) => {
      if (item?.tag?.isSaved === false || typeof item?.tag === "undefined") {
        output = true;
      }
    });

    return output;
  };

  /**
   * Cancel Event
   * Description: cancel all events before save alert
   */
  cancelEvent = async () => {
    if (this.props.preCommit.length > 0) {
      // await for all promises
      await Promise.all(
        this.props.preCommit.map(async (item) => {
          const id = await removeFile(item, this.alertData);
          if (this.editMode) {
            this.props.removePreCommitFiles(id);
          }
        })
      );

      window.location.href = DASHBOARD;
    } else {
      window.location.href = DASHBOARD;
    }
  };

  /**
   * OnSave
   * Send and verify form data
   * @param {any} e
   * @return {void}
   */
  onSave = (e) => {
    e.preventDefault();
    const { id, editMode, user, alert } = this.props;
    const { verticals } = alert;

    if (this.hasTagsUnsaved()) {
      this.setState({
        ...this.state,
        dialog: {
          title: "Existem tags não salvas",
          description: `Verifique na seção "ANEXOS" os arquivos que estão com tags não salvas`,
          show: true,
          cancelFn: () =>
            this.setState({
              ...this.state,
              dialog: {
                ...this.state.dialog,
                show: false,
              },
            }),
        },
      });
      return;
    }

    const assets_id = this.props.alertAsset.map((item) => item.id_file);
    const _alert = {
      email: user.email,
      verticals: this.state.verticals.map((e) => e.value),
      content: this.state.editor
        ? this.state.editor
        : this.props.alertData.content,
      assets: assets_id,
      title: this.props.alertData.title || this.state.title,
      tags: this.props.alertData.tags,
      custom_delivery: this.state.custom_delivery
    };
    
    [...e.target].forEach((e) => {
      if (e.name) Object.assign(_alert, { [e.name]: e.value });
    });

    const valid = [];
    let has_errors = [];

    for (const key in _alert) {
      if (_alert[key] === "" && key !== "link" && key !== "file") {
        has_errors.push(key);
      }
      if (_alert.hasOwnProperty(key)) {
        if (key === "verticals" && _alert[key].length < 1) {
          valid.push(key);
        }
      }
    }

    if (_alert["verticals"].length === 0) {
      has_errors.push("verticals");
    }

    if (_alert.content === undefined) {
      has_errors.push("editor");
    }

    if (valid.length > 0 || has_errors.length > 0) {
      valid.push(has_errors);

      const HTML_ERRORS = has_errors
        .map((error) => `<li>${error}</li>`)
        .join("");

      this.setState({
        ...this.state,
        dialog: {
          title: "Dados inválidos",
          description: `<div>Existem campos não preenchidos:</div> <ul>${HTML_ERRORS}</ul>`,
          show: true,
          cancelFn: () =>
            this.setState({
              ...this.state,
              dialog: {
                ...this.state.dialog,
                show: false,
              },
            }),
        },
      });
      return;
    }

    if (editMode) {
      this.setState({
        ...this.state,
        dialog: {
          title: "Deseja alterar o alerta?",
          description: `Ao clicar em confirmar iremos salvar os alertas`,
          show: true,
          type: "confirm",
          cancelFn: () =>
            this.setState({
              ...this.state,
              dialog: {
                ...this.state.dialog,
                show: false,
              },
            }),
          successFn: async () => {
            // has files attached?
            this.setState({
              ...this.state,
              dialog: {
                ...this.state.dialog,
                show: false,
              },
            });

            // check if has main attachment selected
            const mainAsset = this.props.preCommit.filter(
              (item) => item.mainAttach || item.leading
            )[0];

            if (mainAsset?.id_file) {
              await updateMainAsset(mainAsset.id_file, mainAsset.label);
            }

            this.props.setAlert(id, _alert);
          },
        },
      });
    } else {
      try {
        this.setState({
          ...this.state,
          dialog: {
            title: "Deseja salvar o alerta?",
            description: `Ao clicar em confirmar iremos salvar os alertas`,
            show: true,
            type: "confirm",
            cancelFn: () =>
              this.setState({
                ...this.state,
                dialog: {
                  ...this.state.dialog,
                  show: false,
                },
              }),
            successFn: async () => {
              this.setState({
                ...this.state,
                dialog: {
                  ...this.state.dialog,
                  show: false,
                },
              });

              // check if has main attachment selected
              const mainAsset = this.props.preCommit.filter(
                (item) => item.mainAttach || item.leading
              )[0];

              if (mainAsset?.id_file) {
                await updateMainAsset(mainAsset.id_file, mainAsset.label);
              }

              this.props.saveAlert(_alert);
            },
          },
        });
      } catch (error) {
        window.alert(error);
        this.setState({
          ...this.state,
          dialog: {
            title: "Erro ao salvar o alerta",
            description: `Por favor entre em contato com o time de tecnologia: <strong>ERR-SAVE</strong>`,
            show: true,
          },
        });
      }
    }
  };

  render() {
    const {
      alert: { title, tags, verticals, verticalsOptions, custom_delivery },
      editMode,
    } = this.props;
    const { loading, tagOptions } = this.state;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <Main>
          <div className="center">
            <JotaAlert
              title={this.state.dialog.title}
              description={this.state.dialog.description}
              type={this.state.dialog.type}
              show={this.state.dialog.show}
              functionCancel={() => this.state.dialog.cancelFn()}
              functionParent={() => this.state.dialog.successFn()}
            />

            <JotaStateUI />
            <Title>{editMode ? "Editar" : "Criar"} alerta</Title>
            {loading && <Loading />}
            {!loading && (
              <form onSubmit={(e) => this.onSave(e)} method="POST">
                <div className="row">
                  <div>
                    <Label htmlFor="title">
                      Título<span>*</span>
                    </Label>
                    <div>
                      <Input
                        type="text"
                        id="title"
                        name="title"
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            title: e.target.value,
                          })
                        }
                        defaultValue={title}
                        maxLength={100}
                      />
                    </div>
                    <Label htmlFor="content">
                      Texto<span>*</span>
                    </Label>
                    <Editor
                      id="content"
                      name="content"
                      apiKey={EDITOR_API}
                      defaultValue={this.state.content}
                      value={this.state.content}
                      init={EDITOR_CONFIG}
                      onEditorChange={(e) =>
                        this.setState({
                          ...this.state,
                          content: e,
                          editor: e,
                        })
                      }
                    />

                    <FileManager
                      assets={this.props.state.alert.assets}
                      alert={this.props.state.alert}
                      editMode={editMode}
                      tagOptions={tagOptions}
                      isPublished={this.props.alertData.status === PUBLISHED}
                    />

                    <Label htmlFor="tags">
                      Tags do alerta <span>*</span>
                    </Label>
                    <div>
                      <Input
                        type="text"
                        id="tags"
                        name="tags"
                        onChange={(e) => {
                          this.setState({ ...this.state, tag: e.target.value });
                        }}
                        defaultValue={tags}
                      />
                    </div>
                  </div>

                  <div>
                    <Label htmlFor="verticals">
                      Segmentação <span>*</span>
                    </Label>
                    {verticals[0] && (
                      <Select
                        id="verticals"
                        isMulti                                           
                        isDisabled={this.state.custom_delivery}
                        isClearable
                        isSearchable              
                        value={this.state.verticals}          
                        options={verticalsOptions}
                        styles={customStyles}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Selecione uma ou mais opções"
                        onInputChange={(e) => e}
                        onChange={(e) => this.handleChangeVerticals(e || [])}
                      />
                    )}
                  </div>
                  <div>
                    <CheckboxLabel>
                      <input
                        type="checkbox"
                        defaultChecked={custom_delivery}
                        onClick={(e) => { this.handledCustomDelivery(e) }}
                      />
                      Usar envio personalizado
                    </CheckboxLabel>
                  </div>
                  <div className="box-button">
                    <Button
                      className="save"
                      type="submit"
                      disabled={!this.props.btnStatus}
                    >
                      Salvar
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          dialog: {
                            title: "Você deseja cancelar?",
                            description: `Todos os dados não salvos serão perdidos`,
                            show: true,
                            type: "confirm",
                            successFn: () => {
                              this.cancelEvent();
                              this.setState({
                                ...this.state,
                                dialog: {
                                  title: "Cancelando Conteúdo",
                                  description: WAIT_FOR_SAVE,
                                  show: true,
                                  type: NOTHING,
                                },
                              });
                            },
                            cancelFn: () =>
                              this.setState({
                                ...this.state,
                                dialog: {
                                  ...this.state.dialog,
                                  show: false,
                                },
                              }),
                          },
                        });
                      }}
                      className="cancel"
                      type="button"
                    >
                      Cancelar
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </Main>
      </>
    );
  }
}

FormAlert.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  showPopUp: PropTypes.func.isRequired,
  saveAlert: PropTypes.func.isRequired,
  stateRedux: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alertData: state.alert.data,
  alertAsset: state.alert.preCommit,
  state: state,
  preCommit: state.alert.preCommit,
  verticalState: state.vertical.data,
  btnStatus: state.alert.btnSaveEnable,
});

export default connect(mapStateToProps, {
  saveAlert,
  setAlert,
  showPopUp,
  addAssetStore,
  deleteAssetStore,
  updateAssetStore,
  updateUITag,
  updateShortURL,
  updateTag,
  cleanAlert,
  removePreCommitFiles,
  clearRedux,
})(FormAlert);
