import { alertsTypes } from '.';

const initialState = {
  data: [],
  search: null,
  type: null,
  pagination: {},
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case alertsTypes.LOAD:
      return {
        ...state,
        data: [],
        error: null,
        loading: true,
      };

    case alertsTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: payload.data.results,
        pagination: {
          page: payload.page,
          type: payload.type,
          count: payload.data.count,
          next: payload.data.next,
          previous: payload.data.previous,
        },
        loading: false,
      };

    case alertsTypes.LOAD_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case alertsTypes.SEARCH:
      return {
        ...state,
        data: [],
        loading: true,
      };

    case alertsTypes.SEARCH_SUCCESS:
      return {
        ...state,
        data: payload.data.results,
        search: payload.search,
        pagination: {
          page: payload.page,
          type: null,
          count: payload.data.count,
          next: payload.data.next,
          previous: payload.data.previous,
        },
        loading: false,
      };

    case alertsTypes.SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case alertsTypes.SEARCH_CLEAR:
      return {
        ...state,
        loading: false,
        search: null,
      };

    case alertsTypes.PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data.filter((e) => e.id !== payload.id)],
      };

    case alertsTypes.UNPUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data.filter((e) => e.id !== payload.id)],
      };

    default:
      return state;
  }
}
