/**
 * Generate Unique ID
 * @returns number
 */
export const generateUID = () => {
    let firstPart = (Math.random() * 46656) | 0;
    let secondPart = (Math.random() * 46656) | 0;
    firstPart = ("000" + firstPart.toString(36)).slice(-3);
    secondPart = ("000" + secondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
};

export const customStylesTags = {
    container: (base) => ({
        ...base,
        flex: 1,
    }),

    dropdownIndicator: (styles) => ({
        ...styles,
        marginRight: 90,
    }),
};

export const customStyles = {
    placeholder: (provided, state) => ({
        ...provided,
        fontWeight: 400,
        fontSize: 17,
        color: "#999",
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 400,
        fontSize: 17,
        color: "#666",
        padding: 8,
        ":hover": {
            backgroundColor: "#e7f7fb",
        },
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: "#e7f7fb",
        fontWeight: 400,
        fontSize: 17,
        color: "#042e3e",
        padding: 2,
    }),
};

/**
   * Checa se os assets foram todos salvos antes de enviar
   */
export const verifyAssets = (assets) => {
    let output = true;
    assets.forEach(item => {
        if (!item.id) {
            output = false;
        }
    });
    return output;
};
