export const loadingTypes = {
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING',
};

export const showLoading = () => async (dispatch) => {
  dispatch({
    type: loadingTypes.SHOW_LOADING,
  });
};

export const hideLoading = () => async (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: loadingTypes.HIDE_LOADING,
    });
  }, 1500);
};
