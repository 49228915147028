import React from 'react';

const Publicar = () => (
  <svg width={20} height={20} viewBox="0 0 20 20">
    <path
      d="M19.474 10c0 1.492-4.241 6.462-9.474 6.462-5.233 0-9.474-4.97-9.474-6.462S4.767 3.538 10 3.538c5.233 0 9.474 4.97 9.474 6.462h0zm-6.316 0c0 1.784-1.414 3.23-3.158 3.23S6.842 11.785 6.842 10 8.256 6.77 10 6.77s3.158 1.446 3.158 3.23h0z"
      stroke="#36434D"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Publicar;
