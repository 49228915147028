import { getPlans } from '../../api';

export const Types = {
  LOAD: 'LOAD_PLANS',
  LOAD_SUCCESS: 'LOAD_PLANS_SUCCESS',
  LOAD_FAILED: 'LOAD_PLANS_FAILED',
}


export const loadPlans = () => async (dispatch) => {
  try {
    const plans = await getPlans();

    const plansList = plans.map((plan) => {
      return {
        value: plan.code,  label:plan.name
      }
    })
    dispatch({
      type: Types.LOAD_SUCCESS,
      payload: plansList,
    });
  } catch (error) {
    const msg = 'Erro ao carregar os planos!';

    dispatch({
      type: Types.LOAD_FAILED,
      payload: { message: msg },
    });
  }
};