export default {
  sans: {
    family: '"Roboto", sans-serif',
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  serif: {
    family: '"Playfair Display", serif',
    bold: 700,
    black: 900,
  },
  condensed: {
    family: '"Oswald", sans-serif',
    light: 300,
    medium: 500,
  },
  system: {
    family:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    regular: 400,
  },
};
