import { contactTypes, assetsAlertsType } from ".";

const initialState = {
  data: [],
  pagination: {},
  error: null,
  loading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case contactTypes.LOAD:
      return {
        ...state,
        data: [],
        error: null,
        loading: true,
      };

    case contactTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: payload.data.results,
        pagination: {
          page: payload.page,
          type: payload.type,
          count: payload.data.count,
          next: payload.data.next,
          previous: payload.data.previous,
        },
        loading: false,
      };

    case contactTypes.LOAD_CONTACTS_BY_SEARCH:
      return {
        ...state,
        data: payload,
        loading: false,
      };

    case contactTypes.LOAD_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case contactTypes.ACTIVE:
      return {
        ...state,
        data: [],
        loading: true,
      };

    case contactTypes.ACTIVE_SUCCESS:
      return {
        ...state,
        data: payload,
      };

    case contactTypes.INACTIVE_SUCCESS:
      return {
        ...state,
        data: payload,
      };

    case contactTypes.DELETE_SUCCESS:
      return {
        ...state,
        data: payload,
      };

    case contactTypes.RESET_CONTACTS:
      return {
        ...state,
        loading: false,
        data: [],
      };

    case assetsAlertsType.DELETE:
      return {
        ...state,
        loading: false,
        data: [],
      };

    default:
      return state;
  }
}
