import React, { useEffect } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { logout, loadUser } from "../../store/login";
import { colors, fonts, rem } from "../../assets/styles";
import LogoAuth from "../../assets/Logo.jsx";
import store from "../../store";

const Header = ({ logout, user }) => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  const { is_admin } = user;
  const _location = window.location.pathname;

  if (user === "logout") {
    logout();
    return <Redirect to={"/"} />;
  }
  return (
    <Main id="menu">
      {user && (
        <>
          <div className="header">
            <div className="center">
              <LogoAuth />
              <UserName>
                <li>Olá, {user.first_name} </li>
                <li> | </li>
                <li>
                  <NavLink to="/" onClick={logout}>
                    Sair
                  </NavLink>
                </li>
              </UserName>
            </div>
          </div>
          <div className="nav">
            <div className="center">
              <Menu>
                <li>
                  <NavLink to="/dashboard" activeClassName="active">
                    Alertas
                  </NavLink>
                </li>
                <li> | </li>
                <li>
                  <NavLink to="/alert/add" activeClassName="active">
                    Novo alerta{" "}
                  </NavLink>
                </li>

                {is_admin && (
                  <>
                    <li> | </li>
                    <li>
                      <NavLink
                        to="/contact"
                        activeClassName={
                          _location === "/contact" ? "active" : ""
                        }
                      >
                        Contatos
                      </NavLink>
                    </li>
                    <li> | </li>
                    <li>
                      <NavLink
                        to="/contact/add"
                        activeClassName={
                          _location === "/contact/add" ? "active" : ""
                        }
                      >
                        Novo Contato{" "}
                      </NavLink>
                    </li>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </>
      )}
    </Main>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = ({ auth }) => ({
  auth,
  user: auth.user,
  isAuthenticated: auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout, loadUser })(Header);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  .header {
    width: 100%;
    background-color: #111;
    .center {
      position: relative;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1140px;
      margin: auto;
      svg {
        display: block;
        height: 30px;
        margin: auto;
      }
    }
  }
  .nav {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #9e9c90;
    .center {
      position: relative;
      height: 60px;
      display: flex;
      justify-content: center;
      max-width: 1140px;
      margin: auto;
    }
  }
`;

const UserName = styled.ul`
  position: absolute;
  right: 8px;
  top: 0px;
  list-style: none;
  display: flex;
  li {
    color: ${colors.red};
    padding: 5px;
  }
`;

const Menu = styled.ul`
  position: absolute;
  right: 4px;
  top: 6px;
  list-style: none;
  display: flex;
  li {
    color: #9e9c90;
    a {
      padding: 8px;
      text-decoration: none;
      cursor: pointer;
      font-size: ${rem(16)};
      font: ${fonts.sans};
      font-weight: ${fonts.sans.medium};
      &:hover,
      &.active {
        color: #b3210c;
      }
    }
  }
`;
