import { css } from 'styled-components';

export const rem = (value) => parseInt(value, 10) / 16 + 'rem';

export const em = (value, context = 16) => parseInt(value, 10) / context + 'em';

export const breakpoints = {
  sm: { columns: 1, gutter: 24 / 2, maxWidth: 540, minWidth: 576 },
  md: { columns: 12, gutter: 12 / 2, maxWidth: 720, minWidth: 768 },
  lg: { columns: 12, gutter: 16 / 2, maxWidth: 1366, minWidth: 992 },
  xl: { columns: 12, gutter: 16 / 2, maxWidth: 1366, minWidth: 1140 },
};

export const breakpoint = Object.keys(breakpoints).reduce((acc, key) => {
  acc[key] = (...args) => css`
    @media (min-width: ${em(breakpoints[key].minWidth)}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
