/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes, { func, objectOf } from 'prop-types';
import Logo from '../../assets/Logo';
import { setAlert } from '../../store/alert';
import { login } from '../../store/login';

const LoginPage = (props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    valid: true,
  });

  const { email, password } = formData;
  const { error, mensage, isAuthenticated } = props.auth;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Main>
      <div className="header-auth">
        <Logo />
      </div>
      <BoxLogin>
        <div className="center">
          <form id="frmLogin" name="frmLogin" method="post" onSubmit={(e) => onSubmit(e)}>
            <h2>Login</h2>
            <h3>Entre com seu e-mail e senha</h3>
            <div className="">
              <label htmlFor="email">ENDEREÇO DE E-MAIL</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email JOTA"
                value={email}
                onChange={(e) => onChange(e)}
                className={error ? 'error' : ''}
              />
            </div>

            <div className="">
              <label htmlFor="password">SENHA</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                className={error ? 'error' : ''}
                onChange={(e) => onChange(e)}
              />
            </div>
            <Button type="submit" id="btnSubmit" name="btnSubmit">
              Acessar
            </Button>
            {error && <Error className="error">{mensage}</Error>}
          </form>
        </div>
      </BoxLogin>
    </Main>
  );
};

LoginPage.propTypes = {
  props: objectOf(PropTypes.any)
};

const mapStateToProps = (state) => ({
  login: state.auth.login,
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, login })(LoginPage);

const Main = styled.div`
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .header-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    svg {
      height: 34px;
      margin: auto;
    }
  }
`;

const BoxLogin = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 380px;
  min-height: 400px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        text-align: center;
        font: 700 35px Playfair Display, serif;
        margin: 25px 0;
      }
      h3 {
        color: #9e9c90;
        content: 'Entre com seu e-mail e senha';
        display: block;
        font: 400 16px Roboto, serif;
        margin: auto;
        margin-top: -20px;
        margin-bottom: 30px;
        text-align: center;
      }
      label {
        display: block;
        width: 290px;
        margin: auto;
        margin-bottom: 6px;
        color: #222;
        font: 13px Roboto, sans-serif;
      }
      input {
        display: block;
        margin: 0 auto 20px;
        text-align: center;
        width: 290px;
        border: 1px solid #dbdad3;
        background-color: #f3f3f3;
        border-radius: 3px;
        font: 300 16px Roboto, sans-serif !important;
        outline: none;
        padding: 5px;
        &::placeholder,
        &:focus {
          font: 300 16px Roboto, sans-serif;
        }
      }
    }
  }
`;

const Button = styled.button`
  width: 170px;
  height: 35px;
  margin: 10px auto;
  box-sizing: border-box;
  background: transparent;
  display: block;
  background: #e84b37;
  border: 0.125rem solid #e84b37;
  border-radius: 0.3125rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font: 500 14px Roboto, sans-serif;
  outline: none;
  text-transform: uppercase;
`;

const Error = styled.p`
  text-align: center;
`;
