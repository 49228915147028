import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { showPopUp } from '../../store/popup';
import { fonts, rem } from '../../assets/styles';
import IconArrowDown from '../../assets/IconArrowDown.svg';
import IconArrowUp from '../../assets/IconArrowUp.svg';
import IconLink from '../../assets/IconLink.svg';
import Publicar from '../../assets/Publicar';
import Remover from '../../assets/Remover';
import Editar from '../../assets/Editar';

const Card = (props) => {
  const [data, setData] = useState({
    expand: false,
    action: null,
    id: null,
  });

  const { created, title, content, assets, status, tags, id } = props;

  const _assets = assets.map((e) => e.url);
  const firstText = (str) => {
    let div = document.createElement('div');
    div.innerHTML = str;
    let arrayHtml = div.getElementsByTagName('p');
    let arrayText = [...arrayHtml].map((e) => e.innerHTML);
    return arrayText;
  };

  const date = moment(created);
  const timeDate = date.utc().subtract(3, 'hours').format('HH:mm - DD-MM-YYYY');
  const arrayText = firstText(content)[0];
  const miniText = `${arrayText}`.substr(0, 245);
  const AllText = `${content}`;

  function remove(id, type) {
    props.showPopUp(id, type);
  }

  function publish(id, type) {
    props.showPopUp(id, type);
  }

  let statusLabel = <span className="no">Não publicado</span>;
  if (status == 'published') {
    statusLabel = <span className="yes">Publicado</span>
  } else if (status == 'publishing') {
    statusLabel = <span className="processing">Publicando</span>
  }

  return (
    <Main>
      <Date>
        <p className="date">{timeDate}</p>{' '}
        <p className="status">{statusLabel}</p>
      </Date>
      <Title>
        <Link to={`/alert/edit/${id}`}>{title}</Link>
      </Title>

      {!data.expand && (
        <Text className="not-expanded">
          {parse(`${miniText}`)}...
          <More onClick={() => setData({ ...data, expand: !data.expand })}>
            <span>MOSTRAR MAIS</span>
            <IconArrowDown />
          </More>
        </Text>
      )}

      {data.expand && (
        <Text className="">
          {parse(`${AllText}`)}
          <Less onClick={() => setData({ ...data, expand: !data.expand })}>
            <span>MOSTRAR MENOS</span>
            <IconArrowUp />
          </Less>
        </Text>
      )}

      <Tags>
        {Array.isArray(tags) &&
          tags.map((tag, i) => <span key={i}>#{tag}</span>)}
      </Tags>

      <Hr />
      <Row>
        <div className="mais">
          {_assets[0] && (
            <Leia>
              <div className="svg">
                <IconLink />
              </div>
              Leia mais
              {_assets.map((e, i) => (
                <a href={e} key={i} target="_blank" rel="noopener noreferrer">
                  {`${e.length > 20 ? `${e.toString().substr(0, 20)}...` : e}`}
                </a>
              ))}
            </Leia>
          )}
        </div>

        <Buttons>
          {status == 'published' && (
            <button onClick={() => remove(id, 'remove')} className="remove">
              <Remover />
              Despublicar
            </button>
          )}
          {status != 'published' && (
            <button disabled={status == 'publishing'} onClick={() => publish(id, 'publish')} className="publish">
              <Publicar />
              Publicar
            </button>
          )}
          <Link to={`/alert/edit/${id}`} className="edit">
            <Editar />
            Editar
          </Link>
        </Buttons>
      </Row>
    </Main>
  );
};

Card.propTypes = {
  showPopUp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  showPopUp: state.popup.showPopUp,
});

export default connect(mapStateToProps, { showPopUp })(Card);

const Main = styled.div`
  &:hover,
  &.not-read {
    border-left: solid 3px #f05841;
    padding-left: 15px;
    hr {
      margin: 18px -15px 0 -15px;
    }
  }
  &.not-read {
    background-color: #f5f5f4;
  }
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dbdad3;
  border-radius: 4px;
  padding: 17px 17px 13px 17px;
  margin-bottom: 36px;
  background-color: #fff;
`;
const Date = styled.div`
  display: flex;
  justify-content: space-between;
  .date {
    margin: 5px 0 0 0;
    font-size: ${rem(15)};
    font-weight: ${fonts.sans.bold};
    font-family: ${fonts.sans};
    color: #9e9c90;
  }
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem(12)};
    font-family: ${fonts.sans};
    font-weight: ${fonts.sans.bold};
    color: #4477dd;
    .yes {
      text-transform: uppercase;
      color: #4ca845;
    }
    .no {
      text-transform: uppercase;
      color: #017afd;
    }
    .processing {
      text-transform: uppercase;
      color: #555;
    }
  }
`;
const Title = styled.h3`
  font-size: ${rem(20)};
  font-weight: ${fonts.sans.bold};
  line-height: 24px;
  margin: 12px 0 0 0;
  a {
    text-decoration: none;
    &:hover {
      color: #f05740;
    }
  }
`;
const Text = styled.div`
  &.not-expanded {
    margin-top: 12px;
    line-height: ${26 / 16};
    margin: 12px 0;
    color: #707070;
    p:last-child {
      padding: 2px 0 2px 5px;
    }
  }

  p {
    line-height: ${26 / 16};
    margin: 12px 0;
    color: #707070;
    &:nth-last-child(-n + 2) {
      display: inline;
    }
  }
`;

const Mostrar = styled.p`
  display: inline;
  cursor: pointer;
  span {
    font-size: ${rem(13.8)};
    font-family: ${fonts.sans};
    font-weight: ${fonts.sans.bold};
    color: #f05841;
    text-decoration: underline;
  }
  svg {
    display: inline;
  }
`;
const Less = styled(Mostrar)`
  padding-left: 4px;
`;
const More = styled(Mostrar)``;

const Tags = styled.p`
  margin: 0;
  margin-top: 12px;
  span {
    font-size: ${rem(13.8)};
    font-weight: ${fonts.sans.bold};
    padding-right: 8px;
  }
`;
// const Image = styled.img``;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  .mais {
    display: flex;
    svg {
      width: 24px;
      height: 24px;
    }
    a {
      text-decoration: underline;
      font-size: ${rem(15)};
      font-family: ${fonts.sans};
      font-weight: ${fonts.sans.bold};
      color: #f05841;
      margin-left: 8px;
      padding-top: 4px;
    }
  }
  .box-buttons {
    display: flex;
  }
`;
const Hr = styled.hr`
  border: none;
  border-top: solid 1px #dbdad3;
  margin: 18px -17px 0 -17px;
`;
const Leia = styled.div`
  display: flex;
  align-items: center;
  .svg {
    margin-right: 10px;
    svg {
      width: 15px;
      height: 15px;
      display: block;
    }
  }
  a {
    text-decoration: underline;
    font-size: ${rem(15)};
    font-family: ${fonts.sans};
    font-weight: ${fonts.sans.bold};
    color: #f05841;
    margin-left: 8px;
    text-decoration: none;
  }
`;
const Buttons = styled.div`
  display: flex;
  button,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 30px;
    border: none;
    font-size: 16px;
    margin-left: 14px;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    outline: none;
    &.remove {
      background-color: #f05740;
    }
    &.edit {
      background-color: #017afd;
    }
    &.publish {
      background-color: #4ca845;
    }
    &:disabled {
      opacity: 0.4 !important;
      cursor: not-allowed;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-left: -7px;
      margin-right: 10px;
      fill: currentColor;
    }
  }
`;
