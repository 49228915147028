import styled from 'styled-components'
import { connect } from "react-redux";
import { generateUID } from './utils';
import { useState } from 'react'

const JotaStateUI = ({ preCommit }) => {
    const [mini, setMini] = useState(true)

    return <>{mini ? <MiniContainer onClick={() => setMini(!mini)}><i className="fa-solid fa-stopwatch"></i></MiniContainer> :
        <StateUIContainer onClick={() => setMini(!mini)}>
            <Title>Atualização</Title>
            <Content>
                {preCommit.map(item =>
                    item.isNew && (
                        <ContentItem key={generateUID()}>
                            {item.name}
                            {item.tag && <TagContainer><i className="fa-solid fa-tag"></i></TagContainer>}
                            {item?.tag?.isSaved && <TagContainer><i className="fa-solid fa-floppy-disk"></i></TagContainer>}
                            {item?.short_url && <TagContainer><i className="fa-solid fa-link"></i></TagContainer>}
                        </ContentItem>)
                )}
            </Content>
        </StateUIContainer>
    }
    </>
}

const mapStateToProps = (state) => ({
    preCommit: state.alert.preCommit,
});

export default connect(mapStateToProps, {})(JotaStateUI);

const MiniContainer = styled.div`
    background: #999;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;

    &:hover{
        background: #000;
        cursor: pointer;
    }
`

const TagContainer = styled.span`
        display: inline-block;
        margin-left: 10px;
        `
const StateUIContainer = styled.div`
        background: #ffffff;
        position: fixed;
        bottom: 0;
        right: 0;
        padding: .5em 1.5em;
        border-radius: 5px;
        margin: 1em;
        z-index: 12147483768;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        border-radius: 10px;
        border: 5px solid #999;
        `

const Title = styled.div`
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        `

const Content = styled.ul`
        font-weight: 500;
        font-size: 12px;
        list-style: none;
        margin: 0;
        padding: 0;
        `

const ContentItem = styled.li`
        display: block;
        opacity: .4;
        border-bottom: 1px dashed #c5c5c5;
        padding: 5px;
        `