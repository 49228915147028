import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { fonts, rem } from '../../assets/styles';
import { loadContactList } from '../../store/contactList';

const Pagination = (props) => {
  const { page, count, next, previous } = props.pagination;
  const nNext = next;
  const nPrev = previous;
  const lastPage = Math.ceil(count / 10);
  const arrayInit = [];

  for (let index = 1; index < lastPage + 1; index++) {
    arrayInit.push(index);
  }

  const arrayButtons = [
    ...arrayInit.filter((e) => {
      if (page === lastPage && (e === lastPage - 1 || e === lastPage - 2)) {
        return e;
      }
      if (page === 1 && (e === page + 1 || e === page + 2)) {
        return e;
      }
      if (
        e === page - 1 ||
        e === page ||
        e === page + 1 ||
        e === 1 ||
        e === lastPage
      ) {
        return e;
      }
      return null;
    }),
  ];

  function sendPage(page) {
    const { type } = props.pagination;
    props.loadContactList(page, type);
  }

  return (
    <Main>
      {count > 1 && (
        <RowButtons>
          <TextButtonLeft disabled={!nPrev} onClick={() => sendPage(page - 1)}>
            ANTERIOR
          </TextButtonLeft>

          {arrayButtons.map((e, i) => {
            const leftPoints = page >= 4 && e === 1;
            const rigthPoints =
              lastPage >= page + 3 && e === lastPage && page < lastPage - 1;
            const active = page === e;
            return (
              <NumberButton
                onClick={() => sendPage(e)}
                key={i}
                className={`
                    ${active ? 'active' : ''} 
                    ${leftPoints ? 'point-left' : ''}
                    ${rigthPoints ? 'point-right' : ''} 
                  `}
              >
                {e}
              </NumberButton>
            );
          })}

          <TextButtonRight disabled={!nNext} onClick={() => sendPage(page + 1)}>
            PRÓXIMA
          </TextButtonRight>
        </RowButtons>
      )}
    </Main>
  );
};

const mapStateToProps = ({ contactList }) => ({ contactList });

export default connect(mapStateToProps, {
  loadContactList,
})(Pagination);

const RowButtons = styled.div`
  min-height: 30px;
  display: flex;
`;

const Main = styled.div`
  margin-top: 30px;
  display: flex;
  min-height: 30px;
  justify-content: center;
`;
const Button = styled.button`
  color: ${(props) => (props.active ? '#FFF' : '#888')};
  background-color: ${(props) => (props.active ? '#e84b3d' : '#FFF')};
  border: 1px sold #888;
  font-size: ${rem(12)};
  outline: none;
`;
const NumberButton = styled(Button)`
  font-weight: ${fonts.sans.bold};
  color: #9e9c90;
  border: 1px solid #9e9c90;
  max-height: 24px;
  min-width: 24px;
  margin: 0 3px;
  border-radius: 3px;
  position: relative;
  &.point-right {
    margin-left: 20px;
    position: relative;
    &::before {
      content: '...';
      position: absolute;
      left: -18px;
    }
    &:hover {
      margin-left: 20px;
    }
  }
  &.point-left {
    margin-right: 20px;
    position: relative;
    &::after {
      content: '...';
      position: absolute;
      right: -18px;
    }
    &:hover {
      margin-right: 20px;
    }
  }
  &.active {
    color: #fff;
    border: 1px solid #e84b3d;
    margin: 0 3px;
    border-radius: 3px;
    background-color: #e84b3d;
  }
`;
const TextButtonLeft = styled(Button)`
  font-weight: ${fonts.sans.bold};
  color: #e84b3d;
  background-color: transparent;
  border: none;
  font-size: ${rem(10)};
  max-height: 24px;
  min-width: 75px;
  position: relative;

  &:disabled {
    color: #ddd;
    &:after {
      content: '';
      border-left: 2px solid #ddd;
      border-top: 2px solid #ddd;
    }
  }
  &:after {
    content: '';
    z-index: 5;
    left: 0;
    top: 6px;
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #e84b3d;
    border-top: 2px solid #e84b3d;
    transform: rotate(-45deg);
  }
`;
const TextButtonRight = styled(Button)`
  font-weight: ${fonts.sans.bold};
  color: #e84b3d;
  background-color: transparent;
  border: none;
  font-size: ${rem(10)};
  max-height: 24px;
  min-width: 75px;
  position: relative;
  &:disabled {
    color: #ddd;
    &:after {
      content: '';
      border-left: 2px solid #ddd;
      border-top: 2px solid #ddd;
    }
  }

  &:after {
    content: '';
    right: 2px;
    top: 6px;
    z-index: 5;
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #e84b3d;
    border-top: 2px solid #e84b3d;
    transform: rotate(135deg);
  }
`;
