import { Types } from '.';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  user: {},
  mensage: '',
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.USER_LOADED:
      const vToken = localStorage.getItem('token');
      return {
        ...state,
        token: vToken,
        isAuthenticated: true,
        user: payload,
      };
    case Types.USER_LOGIN:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        token: payload.token,
      };
    case Types.AUTH_ERROR:
      return {
        isAuthenticated: false,
        error: true,
        user: 'logout',
        mensage: 'Usuário e/ou senha inválido',
      };
    case Types.NOT_AUTH:
      return {
        isAuthenticated: false,
        error: true,
        user: 'logout',
        mensage: 'Usuário não autorizado',
      };
    case Types.USER_LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        error: null,
        isAuthenticated: false,
        user: {},
      };
    default:
      return state;
  }
}
