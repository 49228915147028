import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from '../../assets/styles';
import { orderBy } from 'lodash';
import { loadAlerts } from '../../store/alertList';
import { resetAlert } from '../../store/alert';
import Card from './Card';
import Pagination from './Pagination';
import Loading from '../layout/Loading';

class List extends Component {
  componentDidMount() {
    this.props.loadAlerts(1, null, null);
    this.props.resetAlert();
  }

  handleChangeScope(type) {
    this.props.loadAlerts(1, type, null);
  }

  render() {
    const { data, pagination, loading, search, error } = this.props.alerts;
    const { count } = pagination;

    return (
      <>
        {loading && <Loading />}

        {!loading && !search && (
          <Filter>
            <input
              id="all"
              type="radio"
              name="filtro"
              value="all"
              defaultChecked={true}
              onClick={() => this.handleChangeScope(null)}
            />
            <label htmlFor="all">Todos</label>
            <input
              id="published"
              type="radio"
              name="filtro"
              value="published"
              onClick={() => this.handleChangeScope('published')}
            />
            <label htmlFor="published">Publicados</label>
            <input
              id="unpublished"
              type="radio"
              name="filtro"
              value="unpublished"
              onClick={() => this.handleChangeScope('unpublished')}
            />
            <label className="last" htmlFor="unpublished">
              Não Publicados
            </label>
          </Filter>
        )}

        {!loading && (
          <Main>
            <div className="center">
              {error && !loading && (
                <Note>
                  <p className="error">{error.message}</p>
                </Note>
              )}
              {data[0] &&
                orderBy(
                  data,
                  ['created', 'title', 'status'],
                  ['desc', 'asc', 'asc']
                ).map((e, i) => <Card key={i} {...e} />)}
              {!data[0] && !error && (
                <Note>
                  <p className="error">Nenhum alerta encontrado</p>
                </Note>
              )}

              {count > 10 && !loading && <Pagination pagination={pagination} />}
            </div>
          </Main>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  alerts: state.alerts,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  loadAlerts,
  resetAlert,
})(List);

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px;
  .center {
    width: 100%;
    margin: auto;
    max-width: 860px;
  }
`;

const Note = styled.div`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dbdad3;
  border-radius: 4px;
  padding: 17px 17px 13px 17px;
  margin-top: 36px;
  background-color: #fff;
  p {
    text-align: center;
  }
`;

const Filter = styled.div`
  margin: auto;
  width: 840px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  z-index: 5;

  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + label {
    position: relative;
    display: inline-block;
    height: 26px;
    padding: 0 10px 0 30px;
    margin: 0 10px 0 0;
    color: #555;
    &:before {
      content: '';
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 22px;
      background-color: #f5f5f4;
      box-shadow: inset 1px 1px 1px #666;
      left: -3px;
      top: -3px;
    }
    &.last {
      padding-right: 0px;
      margin-right: 0px;
    }
  }

  input[type='radio']:checked + label {
    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${colors.red};
      left: 3px;
      top: 3px;
    }
  }
`;
