import React from 'react';

const Remover = () => (
  <svg width={20} height={20} viewBox="0 0 20 20">
    <g
      stroke="#36434D"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.363 3.363l13.274 13.274M16.637 3.363L3.363 16.637" />
    </g>
  </svg>
);

export default Remover;
