export const popUpTypes = {
  SHOW: "SHOW_POPUP",
  HIDE: "HIDE_POPUP",
};

export const showPopUp = (id, type, data, asset, deleteFile) => async (
  dispatch
) => {
  dispatch({
    type: popUpTypes.SHOW,
    payload: { id, type, data, asset, deleteFile },
  });
};

export const hidePopUp = () => async (dispatch) => {
  dispatch({
    type: popUpTypes.HIDE,
  });
};
