import React from "react";

function TrashCanIcon() {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00004 10.6667C1.00004 11.4 1.60004 12 2.33337 12H7.66671C8.40004 12 9.00004 11.4 9.00004 10.6667V4C9.00004 3.26667 8.40004 2.66667 7.66671 2.66667H2.33337C1.60004 2.66667 1.00004 3.26667 1.00004 4V10.6667ZM9.00004 0.666667H7.33337L6.86004 0.193333C6.74004 0.0733333 6.56671 0 6.39337 0H3.60671C3.43337 0 3.26004 0.0733333 3.14004 0.193333L2.66671 0.666667H1.00004C0.633374 0.666667 0.333374 0.966667 0.333374 1.33333C0.333374 1.7 0.633374 2 1.00004 2H9.00004C9.36671 2 9.66671 1.7 9.66671 1.33333C9.66671 0.966667 9.36671 0.666667 9.00004 0.666667Z"
        fill="#FB4A3E"
      />
    </svg>
  );
}

export default TrashCanIcon;
