import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Header from "../layout/Header";
import PopUp from "../layout/PopUp";
import Form from "./FormAlert";
import { getAlert } from "../../store/alert";
import { loadVerticals } from "../../store/vertical";
import { useDispatch } from "react-redux";

const AlertEdit = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const id = window.location.pathname.split("/edit/")[1];
    props.getAlert(id);
    dispatch(loadVerticals());
  }, []);

  const id = window.location.pathname.split("/edit/")[1];
  const {
    verticals,
    title,
    content,
    assets,
    tags,
    contact_filters,
    custom_delivery
  } = props.alert.data;

  const verticalsOptions = props.verticals.data;

  let _verticals =
    contact_filters !== undefined
      ? verticalsOptions.filter((e) => contact_filters.includes(e.value))
      : [];
  const data = {
    verticals: _verticals,
    assets,
    title,
    verticalsOptions,
    content,
    tags,
    contact_filters: _verticals,
    custom_delivery: custom_delivery
  };

  return (
    <Main>
      <Header />
      <Form
        alert={data}
        user={props.user}
        editMode={true}
        id={id}
        old_assets={assets}
      />
      <PopUp />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  alert: state.alert,
  verticals: state.vertical,
});

export default connect(mapStateToProps, {
  getAlert,
})(AlertEdit);

const Main = styled.div``;
