import styled from "styled-components";
import { useState, useEffect } from "react";
import { CONFIRM, NOTHING } from "./contants";

const JotaAlert = ({
    title,
    description,
    functionParent,
    functionCancel,
    type,
    show,
}) => {
    const [showState, setShowState] = useState(false);

    useEffect(() => {
        setShowState(show);
    }, [show]);

    return (
        showState && (
            <JotaAlertContainer>
                <BackDrop>
                    <InnerContainer>
                        <Title>{title}</Title>
                        <Description dangerouslySetInnerHTML={{ __html: description }} />
                        <Footer>
                            {type === CONFIRM ? (
                                <>
                                    <Button background="#dedef0" onClick={() => functionParent()}>
                                        Confirmar
                                    </Button>
                                    <Button
                                        background="#ebebeb"
                                        onClick={() => {
                                            if (functionCancel) {
                                                functionCancel();
                                            }
                                            setShowState(false);
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                </>
                            ) : (
                                type !== NOTHING && <Button background="none" onClick={() => {
                                    if (type === CONFIRM) {
                                        if (functionParent) {
                                            functionParent()
                                        }
                                    }
                                    functionCancel()
                                    setShowState(false)
                                }}>OK</Button>
                            )}
                        </Footer>
                    </InnerContainer>
                </BackDrop>
            </JotaAlertContainer>
        )
    );
};

export default JotaAlert;

const JotaAlertContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12147483768;
`;
const BackDrop = styled.div`
  background: #000000c2;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all .2s;
`;

const InnerContainer = styled.div`
  background: white;
  padding: 1em;
  border-radius: 0.5em;
  border: 5px solid #f2f2f2;
  box-shadow: 19px 20px 2px rgba(0, 0, 0, 0.2);
  transition: all .2s;
  max-width: 300px;
`;
const Title = styled.h4`
    margin: 0;
    font-size: 20px;
    margin-bottom: 10px;
    padding: 10px 0px;
    border-bottom: 1px solid orangered;
    color: orangered;
`;
const Description = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
  padding-top: 0.5em;
`;

const Button = styled.button`
  border: none;
  padding: 0.5em 1em;
  margin-right: 5px;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  background: ${(props) => props.background ?? "red"};

  &:hover {
    background: black;
    color: white;
    cursor: pointer;
  }
`;
