import React from 'react';

function IconArrowUp() {
  return (
    <svg width={10} height={10} viewBox="0 0 10 10">
      <defs>
        <clipPath id="prefix__a">
          <path className="prefix__a" d="M0 0h10v10H0z" />
        </clipPath>
        <style>{'.prefix__a{fill:none}'}</style>
      </defs>
      <g transform="rotate(180 5 5)" clipPath="url(#prefix__a)">
        <path
          d="M7.451 3.208L4.997 5.559 2.55 3.208l-.753.724 3.2 3.08 3.2-3.08z"
          fill="#f05841"
        />
        <path className="prefix__a" d="M0 0h10v10H0z" />
      </g>
    </svg>
  );
}

export default IconArrowUp;
