import React from 'react';
import styled from 'styled-components';

export default function Loading() {
  return (
    <Spinner>
      <svg className="prefix__svg-loader" viewBox="0 0 80 80">
        <path
          fill="#D43B11"
          d="M40 72C22.4 72 8 57.6 8 40S22.4 8 40 8s32 14.4 32 32c0 1.1-.9 2-2 2s-2-.9-2-2c0-15.4-12.6-28-28-28S12 24.6 12 40s12.6 28 28 28c1.1 0 2 .9 2 2s-.9 2-2 2z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 40 40"
            to="360 40 40"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </Spinner>
  );
}

const Spinner = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  &.popup {
    align-items: top;
    position: relative;
    height: 80px !important;
    width: 80px !important;
  }
  svg {
    margin: auto;
    position: relative;
    width: 70px;
    height: 70px;
  }
`;
