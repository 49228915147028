import { create, getAlertByID, setAlertByID } from "../../api";

export const Types = {
  GET_ALERT: "GET_ALERT",
  GET_ALERT_SUCCESS: "GET_ALERT_SUCCESS",
  SET_ALERT: "SET_ALERT",
  SET_ALERT_SUCCESS: "SET_ALERT_SUCCESS",
  SAVE_ALERT_SUCCESS: "SAVE_ALERT_SUCCESS",
  RESET_ALERT: "RESET_ALERT",
  ERROR: "ERROR",
  ADD_PRE_COMMIT_FILES: "ADD_PRE_COMMIT_FILES",
  REMOVE_PRE_COMMIT_FILES: "REMOVE_PRE_COMMIT_FILES",
  ALERTS_ASSETS_UPDATE_UI: "ALERTS_ASSETS_UPDATE_UI",
  ALERTS_ASSETS_UPDATE_TAG: "ALERTS_ASSETS_UPDATE_TAG",
  ALERTS_ASSETS_UPDATE_SHORTURL: "ALERTS_ASSETS_UPDATE_SHORTURL",
  CLEAR_REDUX: "CLEAR_REDUX",
  UPDATE_MAIN_ATTACH: "UPDATE_MAIN_ATTACH",
  UPDATE_MAIN_TEXT: "UPDATE_MAIN_TEXT",
  BTN_SAVE_TOOGLE: "BTN_SAVE_TOOGLE"
};

export const resetAlert = () => async (dispatch) => {
  dispatch({
    type: Types.RESET_ALERT,
  });
};

export const setAlert = (id, alert) => async (dispatch) => {
  try {
    const resp = await setAlertByID(id, alert);

    if (resp.status === 200) {
      dispatch({
        type: Types.SAVE_ALERT_SUCCESS,
        payload: resp,
      });
      dispatch({
        type: "SHOW_POPUP",
        payload: { id: 0, type: "create-success" },
      });
    }

    if (resp.status === 400) {
      dispatch({
        type: "SHOW_POPUP",
        payload: { id: 0, type: "create-failed" },
      });
    }
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: error },
    });
  }
};

export const getAlert = (id, isPublished = false, editMode = false) => async (dispatch) => {
  try {
    let alert = await getAlertByID(id);
    alert = {
      ...alert,
      assets: alert.assets.map(item => {
        item.isNew = false
        return item
      })
    }
    dispatch({
      type: Types.GET_ALERT_SUCCESS,
      payload: alert,
    });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: error },
    });
  }
};

export const saveAlert = (alert) => async (dispatch) => {
  try {
    const newAlert = await create(alert);
    if (newAlert.status === 201) {
      dispatch({
        type: Types.SAVE_ALERT_SUCCESS,
        payload: newAlert,
      });
      dispatch({
        type: "SHOW_POPUP",
        payload: { id: 0, type: "create-success" },
      });
    }

    if (newAlert.status === 400) {
      dispatch({
        type: "SHOW_POPUP",
        payload: { id: 0, type: "create-failed" },
      });
    }
  } catch (error) {
    dispatch({
      type: Types.ERROR,
      payload: {
        error: { message: error },
      },
    });
  }
};

export const addAssetStore = (filename, tag, id, link, file) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "ALERTS_ASSETS_ADD",
      payload: {
        filename,
        tag,
        id,
        link,
        file,
      },
    });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: `${error} - 9` },
    });
  }
};

export const deleteAssetStore = (file) => async (dispatch) => {
  try {
    dispatch({
      type: "ALERTS_ASSETS_REMOVE",
      payload: file,
    });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: `${error} - 8` },
    });
  }
};

export const updateUITag = (file) => async (dispatch) => {
  try {
    dispatch({
      type: Types.ALERTS_ASSETS_UPDATE_UI,
      payload: file,
    });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: `${error} - 7` },
    });
  }
};

export const updateShortURL = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: Types.ALERTS_ASSETS_UPDATE_SHORTURL,
      payload: {
        data,
        id
      },
    });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: `${error} - 6` },
    });
  }
};

export const updateTag = (id, tag) => async (dispatch) => {
  try {
    dispatch({
      type: Types.ALERTS_ASSETS_UPDATE_TAG,
      payload: {
        id,
        tag,
      },
    });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: `${error} - 5` },
    });
  }
};

export const updateAssetStore = (file) => async (dispatch) => {
  try {
    dispatch({
      type: "ALERTS_ASSETS_UPDATE",
      payload: file,
    });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: `${error} - 4` },
    });
  }
};

export const cleanAlert = () => async (dispatch) => {
  try {
    dispatch({
      type: "ALERT_CLEAN",
    });
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: { message: `${error} - 3` },
    });
  }
};


/**
 * Add files to Pre Commit Redux
 */
export const addPreCommitFiles = (file) => async (dispatch) => {
  try {
    dispatch({
      type: Types.ADD_PRE_COMMIT_FILES,
      payload: file,
    });
  } catch (error) {
    dispatch({
      type: "ERROR PRE COMMIT",
      payload: { message: `${error} - 2` },
    });
  }
}

export const removePreCommitFiles = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Types.REMOVE_PRE_COMMIT_FILES,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: "ERROR PRE COMMIT",
      payload: { message: `${error} - 1` },
    });
  }
}

export const clearRedux = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Types.CLEAR_REDUX,
    });
  } catch (error) {
    dispatch({
      type: "ERROR PRE COMMIT",
      payload: { message: `${error} - 10` },
    });
  }
}

export const updateMainAttach = (id) => async (dispatch) => {
  try {
    dispatch({
      type: Types.UPDATE_MAIN_ATTACH,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: "ERROR PRE COMMIT",
      payload: { message: `${error} - 10` },
    });
  }
}

export const updateMainText = (text) => async (dispatch) => {
  try {
    dispatch({
      type: Types.UPDATE_MAIN_TEXT,
      payload: text,
    });
  } catch (error) {
    dispatch({
      type: "ERROR PRE COMMIT",
      payload: { message: `${error} - 10` },
    });
  }
}

export const btnSaveToogle = (value) => async (dispatch) => {
  try {
    dispatch({
      type: Types.BTN_SAVE_TOOGLE,
      payload: value
    });
  } catch (error) {
    dispatch({
      type: 'ERROR TOOGLE BTN',
      payload: { message: `${error} - 11` },
    })
  }
}