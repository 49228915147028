/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Loading from '../layout/Loading';
import { saveContact, setContact } from '../../store/contact';
import { showPopUp } from '../../store/popup';

class FormContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagStar: false,
      loading: false,
      redirect: null,
      flagPlans: false,
      plans: [],
      flagVerticals: false,
      verticals: [],
      contact: {}
    };
  }

  onSave = (e, verticals) => {
    e.preventDefault();
    const { editMode, contact, saveContact, setContact, showPopUp } = this.props;
    const { id } = contact.data;

    const onContact = {
      content_settings: {
        web_push_enabled: e.target.web_push_enabled.value,
        whatsapp_enabled: e.target.whatsapp_enabled.value,
        email_enabled: e.target.email_enabled.value
      },
      verticals: this.state.flagVerticals ? this.state.contact.verticals : verticals,
    };

    [...e.target].forEach((c) => {
      if (c.name) Object.assign(onContact, { [c.name]: c.value });
    });

    delete onContact.web_push_enabled;
    delete onContact.whatsapp_enabled;
    delete onContact.email_enabled;

    const validationFields = ['first_name', 'last_name', 'content_settings', 'email', 'company', 'persona', 'is_active'];
    const invalidFields = validationFields.reduce((invalidFieldsArr, fieldName) => {
        if ([undefined, ''].includes(onContact[fieldName])) {
          invalidFieldsArr.push(fieldName);
        }
        return invalidFieldsArr;
    }, []);

    if (onContact.phone === '') onContact.phone = null;

    if (invalidFields.length > 0) {
      showPopUp('', 'valid-contact-create', invalidFields);
    } else {
      if (editMode) {
        setContact(id, onContact);
      } else {
        saveContact(onContact);
      }
    }
    
  };

  clearSelected(e) {
    return e;
  }

  toRedirect(to) {
    this.setState({ redirect: to });
  }

  handleChangeVerticals(e) {
    const vl = e.map((e) => e.value);
    this.setState({ contact: { verticals: [...vl] }, flagVerticals: true });
  }

  render() {
    const { editMode, contact, showPopUp } = this.props;
    const { redirect } = this.state;
    const { data, plans, verticalsOptions, companies } = contact;
    const {
      company,
      email,
      business_role,
      first_name,
      last_name,
      phone,
      verticals,
      persona,
      is_active,
      notes,
      content_settings,
    } = data;

    const options = [
      { label: 'Ativo', value: true },
      { label: 'Inativo', value: false },
    ];

    const statusStyles = {
      singleValue: (provided) => ({
        ...provided,
        fontWeight: 400,
        fontSize: 17,
        color: '#666',
        padding: 4,
      }),
      option: (provided, state) => ({
        ...provided,
        fontWeight: 400,
        fontSize: 17,
        color: state.isSelected ? 'white' : '#666',
        padding: 8,
      }),
    };

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        <Main>
          <div className="center">
            <Title>{editMode ? 'Editar' : 'Criar'} contato</Title>
            {!company && <Loading />}
            {company && (
              <form onSubmit={(e) => this.onSave(e, verticals)} method="POST">
                <div className="row">
                  <div className="colmn50">
                    <Label htmlFor="first_name">
                      Nome
                      <span>*</span>
                    </Label>
                    <div>
                      <Input
                        type="text"
                        id="first_name"
                        name="first_name"
                        defaultValue={first_name}
                      />
                    </div>
                  </div>

                  <div className="colmn50">
                    <Label htmlFor="last_name">
                      Sobrenome
                      <span>*</span>
                    </Label>
                    <div>
                      <Input type="text" id="last_name" name="last_name" defaultValue={last_name} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="colmn50">
                    <Label htmlFor="email">
                      Email
                      <span>*</span>
                    </Label>
                    <div>
                      <Input type="text" id="email" name="email" defaultValue={email} />
                    </div>
                  </div>

                  <div className="colmn50">
                    <Label htmlFor="phone">Celular</Label>
                    <Input
                      id="phone"
                      type="tel"
                      placeholder="000 (00) 00000 0000"
                      name="phone"
                      defaultValue={phone}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="colmn50">
                    <Label htmlFor="company">
                      Empresa
                      <span>*</span>
                    </Label>
                    <div>
                      {company && (
                        <Select
                          id="company"
                          name="company"
                          defaultValue={companies
                            .filter((e) => e.id === company)
                            .map((e) => ({ label: e.name, value: e.id }))}
                          isClearable
                          onChange={this.handleChange}
                          onInputChange={this.handleInputChange}
                          styles={statusStyles}
                          options={companies.map((e) => ({
                            label: e.name,
                            value: e.id,
                          }))}
                        />
                      )}
                    </div>
                  </div>
                  <div className="colmn50">
                    <Label htmlFor="business_role">Cargo/Profissão</Label>
                    <div>
                      <Input
                        type="text"
                        id="business_role"
                        name="business_role"
                        onChange={this.onChange}
                        defaultValue={business_role}
                      />
                    </div>
                  </div>
                </div>

                <Subtitle>Planos: </Subtitle>
                <div className="row">
                  <div className="colmn50">
                    <Label htmlFor="is_active">
                      Status
                      <span>*</span>
                    </Label>
                    <div>
                      {company && (
                        <Select
                          id="is_active"
                          name="is_active"
                          defaultValue={
                            options.filter((e) => !!e.value === is_active)
                            // .map(e => ({label: e.name, value: e.id}))
                          }
                          isClearable
                          onChange={this.handleChange}
                          onInputChange={this.handleInputChange}
                          styles={statusStyles}
                          options={options}
                        />
                      )}
                    </div>
                  </div>

                  <div className="colmn50">
                    <div>
                      <Label htmlFor="persona">
                        Persona
                        <span>*</span>
                      </Label>
                      {plans && (
                        <Select
                          id="persona"
                          name="persona"
                          defaultValue={plans
                            .filter((e) => e.id === persona)
                            .map((e) => ({ label: e.name, value: e.id }))}
                          isClearable
                          isSearchable
                          options={plans.map((e) => ({
                            label: e.name,
                            value: e.id,
                          }))}
                          styles={statusStyles}
                          onChange={this.handleChange}
                          onInputChange={this.handleInputChange}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="colmn100">
                  <Label htmlFor="verticals">
                    Verticais <span>*</span>
                  </Label>
                    <div>
                      {verticals && (
                        <Select
                          id="verticals"
                          defaultValue={verticalsOptions
                            .filter((e) => verticals.includes(e.id))
                            .map((e) => ({ label: e.name, value: e.id }))}
                          isMulti
                          isClearable
                          isSearchable
                          options={verticalsOptions.map((e) => ({
                            label: e.name,
                            value: e.id,
                          }))}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Selecione uma ou mais opções"
                          onInputChange={(e) => this.clearSelected(e)}
                          onChange={(e) => this.handleChangeVerticals(e || [])}
                        />
                    )}
                    </div>
                  </div>
                </div>

                <Subtitle>Notificações: </Subtitle>
                <div className="row">
                  <div className="colmn50">
                    <Label htmlFor="web_push_enabled">Web push (Browser)</Label>
                    <div>
                      {company && (
                        <Select
                          id="web_push_enabled"
                          name="web_push_enabled"
                          defaultValue={options.filter(
                            (e) => !!e.value === content_settings.web_push_enabled
                          )}
                          isClearable
                          onChange={this.handleChange}
                          onInputChange={this.handleInputChange}
                          styles={statusStyles}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                  <div className="colmn50">
                    <Label htmlFor="whatsapp_enabled">Whatsapp</Label>
                    <div>
                      {company && (
                        <Select
                          id="whatsapp_enabled"
                          name="whatsapp_enabled"
                          defaultValue={options.filter(
                            (e) => !!e.value === content_settings.whatsapp_enabled
                          )}
                          isClearable
                          onChange={this.handleChange}
                          onInputChange={this.handleInputChange}
                          styles={statusStyles}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="colmn50">
                    <Label htmlFor="email_enabled">Email</Label>
                    <div>
                      {company && (
                        <Select
                          id="email_enabled"
                          name="email_enabled"
                          defaultValue={options.filter(
                            (e) => !!e.value === content_settings.email_enabled
                          )}
                          isClearable
                          onChange={this.handleChange}
                          onInputChange={this.handleInputChange}
                          styles={statusStyles}
                          options={options}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <Subtitle>Observações: </Subtitle>
                <div className="row">
                  <div className="colmn100">
                    <Textarea id="notes" name="notes" rows="5" defaultValue={notes} />
                  </div>
                </div>

                <div className="box-button">
                  <Button onClick={() => showPopUp('', 'loading')} className="save" type="submit">
                    Salvar
                  </Button>
                  <Button
                    onClick={() => showPopUp('', 'valid-cancel')}
                    className="cancel"
                    type="button"
                  >
                    Cancelar
                  </Button>
                </div>
              </form>
            )}
          </div>
        </Main>
      </>
    );
  }
}

FormContact.propTypes = {
  editMode: PropTypes.bool.isRequired,
  contact: PropTypes.object.isRequired,
  showPopUp: PropTypes.func.isRequired,
  setContact: PropTypes.func.isRequired,
  saveContact: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  contact: state.contact,
});

export default connect(mapStateToProps, { saveContact, setContact, showPopUp })(FormContact);

const Title = styled.h3`
  margin: 40px auto 10px;
  max-width: 1030px;
`;

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 70px auto 20px;
  font: lighter 16px/20px 'Roboto', sans-serif;

  .row {
    display: flex;
    .colmn30,
    .colmn40,
    .colmn50,
    .colmn60,
    .colmn70,
    .colmn100 {
      padding: 0 10px;
      box-sizing: border-box;
    }
    .colmn30 {
      flex: 3;
    }
    .colmn40 {
      flex: 4;
    }
    .colmn50 {
      flex: 5;
    }
    .colmn60 {
      flex: 6;
    }
    .colmn70 {
      flex: 7;
    }
    .colmn100 {
      flex: 1;
    }
  }

  .center {
    width: 100%;
    margin: auto;
    max-width: 1100px;
    background-color: #fff;
    border: 1px solid #dbdad3;
    border-radius: 5px;
    padding: 0 24px 40px;
    box-sizing: border-box;
    h3 {
      color: #b3210c;
      font-size: 22px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    .box-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      margin-right: 10px;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid #dbdad3;
  border-radius: 6px;
  background-color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  color: #444;
  font: 400 19px/28px 'Roboto', sans-serif;
  outline: none;
`;

const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid #dbdad3;
  border-radius: 6px;
  background-color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  color: #444;
  font: 400 19px/28px 'Roboto', sans-serif;
  outline: none;
`;

const Label = styled.label`
  font: 400 18px/24px 'Roboto', sans-serif;
  padding: 20px 0 10px;
  width: 100%;
  float: left;
  span {
    font: 400 16px/20px 'Roboto', sans-serif;
    color: #b3210c;
    font-style: italic;
    margin-left: 4px;
  }
`;

const Button = styled.button`
  width: 150px;
  height: 34px;
  margin: 10px 0 0 15px;
  box-sizing: border-box;
  background: transparent;
  display: block;
  border-radius: 0.3125rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font: 500 17px Roboto, sans-serif;
  outline: none;
  &.save {
    background: #489e06;
    border: 1px solid #489e06;
  }
  &.cancel {
    background: #e84b37;
    border: 1px solid #e84b37;
  }
`;

const Subtitle = styled.h4`
  color: #000;
  font: 200 19px/28px 'Roboto', sans-serif;
  padding-left: 10px;
  margin: 30px 0 5px;
`;
