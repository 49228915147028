import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { rem, fonts } from "../../assets/styles";
import { orderBy } from "lodash";
import { loadContactList, resetContactList, loadContactBySearch } from "../../store/contactList";
import Row from "./Row";
import Pagination from "./Pagination";
import Loading from "../layout/Loading";
import http, { cmsURL } from "./../../api/http.service";

class Table extends Component {
  componentDidMount() {
    this.props.loadContactList(1, null);
    this.props.resetContactList();
    this.getCompanies();

    this.setState({
      companies: null,
      search: {
        company: "",
        email: "",
        status: false,
        data: [],
      },
    });
  }

  async getCompanies() {
    const requestCompany = await http.get(`${cmsURL}/alerts/companies/`);
    this.setState({
      ...this.state,
      companies: requestCompany.data,
    });
  }

  handleChangeScope(type) {
    this.props.conatctList(1, type);
  }

  /**
   * Search Event Submit
   */
  async search() {
    let searchResult = null;

    // only e-mail
    if (this.state.search.email !== "" && this.state.search.company === "") {
      searchResult = await http.get(`${cmsURL}/alerts/contacts/?email=${this.state.search.email}`);
    }

    // only company
    else if (this.state.search.company !== "" && this.state.search.email === "") {
      searchResult = await http.get(`${cmsURL}/alerts/contacts/?company=${this.state.search.company}`);
    }

    // company and e-mail
    else if(this.state.search.company !== "" && this.state.search.email !== ""){
      searchResult = await http.get(
        `${cmsURL}/alerts/contacts/?company=${this.state.search.company}&email=${this.state.search.email}`
      );
    }


    if(this.state.search.company === "" && this.state.search.email === ""){
      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          status: false,
          data: [],
        },
      });
    }

    else {
      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          status: true,
          data: searchResult.data.results,
        },
      });
      this.props.loadContactBySearch(searchResult.data.results)
    }
  }

  /**
   * Clear Search
   */
  clearSearch() {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        status: false,
        data: [],
        email: "",
        company: "",
      },
    });

    this.props.loadContactList(1, null);
    this.props.resetContactList();
    this.getCompanies();

    document.getElementById("cEmpresa").selectedIndex = 0;
    document.getElementById("cEmail").value = "";
  }

  render() {
    const { data, pagination, loading, error } = this.props.contactList;
    const { count } = pagination;
    const { is_admin } = this.props.auth.user;

    if (!is_admin) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <>
        {loading === "show" && <Loading />}
        {!loading && (
          <Main>
            {error && !loading && (
              <Note>
                <p className="error"> {error.message} </p>
              </Note>
            )}
            <ContainerTable>
              <>
                <SearchTitle> Busca por Contatos </SearchTitle>
                <Search>
                  <SearchFields>
                    <FieldForm>
                      <label htmlFor="cEmail"> E - mail </label>
                      <input
                        type="text"
                        placeholder="pesquisar por e-mail"
                        id="cEmail"
                        defaultValue={this.state && this.state.search && this.state.search.email}
                        onKeyDown={async e => {
                          if(e.key === 'Enter'){
                            await this.setState({
                              ...this.state,
                              search: {
                                ...this.state.search,
                                email: e.target.value,
                              },
                            });
                            this.search();
                          }
                        }}
                        onChange={(e) => {
                          this.setState({
                            ...this.state,
                            search: {
                              ...this.state.search,
                              email: e.target.value,
                            },
                          });
                        }}
                      />
                    </FieldForm>
                    {this.state && this.state.companies && this.state.companies.length > 0 && (
                      <FieldForm>
                        <label htmlFor="cEmpresa"> Empresa </label>
                        <select
                          name="cEmpresa"
                          id="cEmpresa"
                          defaultValue="selecione uma empresa"
                          onChange={(e) => {
                            this.setState({
                              ...this.state,
                              search: {
                                ...this.state.search,
                                company: e.target.value,
                              },
                            });
                          }}
                        >
                          <option value="" selected>
                            Selecione uma empresa
                          </option>
                          {this.state.companies.map((item, key) => (
                            <option value={item.name} key={key}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </FieldForm>
                    )}
                  </SearchFields>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <ButtonSearch onClick={() => this.search()}> Pesquisar </ButtonSearch>
                    <ButtonClean onClick={() => this.clearSearch()}>Limpar a busca</ButtonClean>
                  </div>
                </Search>
              </>

              {this.state && this.state.search && this.state.search.status && (
                <div style={{ margin: "1em 0" }}>
                  Foram encontrados <strong>{this.state.search.data.length}</strong> registros
                </div>
              )}

              {data  ? (
                <Tables className="center">
                  <thead>
                    <tr className="row">
                      <th>Início</th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Celular</th>
                      <th>Atividade</th>
                      <th>Empresa</th>
                      <th>Persona</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {count > 0  && orderBy(data, ["first_name", "email"], ["asc", "desc"]).map((e, i) => <Row key={i} {...e} />)
                      }
                  </tbody>
                </Tables>
              ) : (
                <h1>Nenhuma empresa encontrada</h1>
              )}
            </ContainerTable>
            {count === 0 && !error && (
              <Note>
                <p className="error"> Nenhum alerta encontrado </p>
              </Note>
            )}
            {count > 10 && !loading && this.state && this.state.search && !this.state.search.status && (
              <Pagination pagination={pagination} />
            )}
          </Main>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  contact: state.contact,
  contactList: state.contactList,
  companies: state.contact.companies,
});

export default connect(mapStateToProps, {
  loadContactList,
  resetContactList,
  loadContactBySearch
})(Table);

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px;
  margin-top: 50px;
  padding: 0 20px;
  align-items: center;
  .center {
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    max-width: 1440px;
    border-spacing: 0px;
  }
`;

const Tables = styled.table`
  width: 1200px;
  thead {
    width: 100%;
    tr {

        background:#ffffff00;
        text-transform: uppercase;
        font-size: 13px;
        padding: 0;
        margin: 0;

        th{
          padding:5px;
        }
    }
    .row {
      width: 100%;
      font-family: ${fonts.sans};
      text-align: left;
      overflow-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      box-sizing: border-box;
      font-size: 12px;
      }
    }
  }
`;

const Note = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 36px;
  p {
    text-align: center;
  }
`;

const ContainerTable = styled.div`
  max-width: 1400px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Search = styled.div`
  display: flex;
  padding: 1em 0 2em 0;
  border-bottom: 1px solid #0000000f;
  margin-bottom: 2em;
  justify-content: space-between;
`;

const FieldForm = styled.div`
  label {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    padding: 5px 0;
    display: inline-block;
    color: #727272;
    margin-right: 1em;
  }

  input,
  select {
    border: none;
    padding: 1em;
    font-size: 14px;
    margin-right: 2em;
    background: white;

    option {
      text-transform: uppercase;
    }
  }

  input:focus-visible {
    outline-color: #e2e2e2;
  }
`;

const SearchFields = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ButtonSearch = styled.button`
  background: #e2e2e2;
  border: none;
  padding: 10px;
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;

  &:hover {
    background: red;
    cursor: pointer;
  }
`;

const ButtonClean = styled.button`
  background: #d3d2d2;
  border: none;
  padding: 10px;
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;

  &:hover {
    background: red;
    cursor: pointer;
  }
`;

const SearchTitle = styled.h4`
  margin: 0;
  padding: 0;
`;
