import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Header from '../layout/Header';
import PopUp from '../layout/PopUp';
import Form from './FormContact';
import { getContact, resetContact } from '../../store/contact';

class ContactEdit extends Component {
  componentDidMount() {
    const id = window.location.pathname.split('/edit/')[1];
    this.props.resetContact();
    this.props.getContact(id);
  }

  render() {
    return (
      <Main>
        <Header />
        <Form editMode />
        <PopUp />
      </Main>
    );
  }
}

ContactEdit.propTypes = {
  resetContact: PropTypes.func.isRequired,
  getContact: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  contact: state.contact,
});

export default connect(mapStateToProps, {
  getContact,
  resetContact,
})(ContactEdit);

const Main = styled.div``;
