import React from 'react';
import styled from 'styled-components';
import Header from '../layout/Header';
import PopUp from '../layout/PopUp';
import List from './List';
import Search from './Search';

const AlertList = () => {
  return (
    <Main>
      <PopUp />
      <Header />
      <Search />
      <List />
    </Main>
  );
};

export default AlertList;

const Main = styled.div``;
