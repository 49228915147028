import { loadingTypes } from '.';

const initialState = {};

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case loadingTypes.SHOW_LOADING:
      return true;

    case loadingTypes.HIDE_LOADING:
      return false;

    default:
      return state;
  }
}
