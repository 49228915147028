import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Header from '../layout/Header';
import { resetContact, getContactData } from '../../store/contact';
import PopUp from '../layout/PopUp';
import Form from './FormContact';

class ContactCreate extends Component {
  componentDidMount() {
    this.props.resetContact();
    this.props.getContactData();
  }

  render() {
    return (
      <Main>
        <Header />
        <Form editMode={false} />
        <PopUp />
      </Main>
    );
  }
}

ContactCreate.propTypes = {
  resetContact: PropTypes.func.isRequired,
  getContactData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  contact: state.contact,
});

export default connect(mapStateToProps, { resetContact, getContactData })(ContactCreate);

const Main = styled.div``;
