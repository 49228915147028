import { newContact, getContactByID, setContactByID, getData } from '../../api';

export const contactTypes = {
  GET_DATA: 'GET_DATA',
  GET_DATA_SUCCESS: 'GET_DATA_SUCCESS',

  GET_CONTACT: 'GET_CONTACT',
  GET_CONTACT_SUCCESS: 'GET_CONTACT_SUCCESS',

  SET_CONTACT: 'SET_CONTACT',
  SET_CONTACT_SUCCESS: 'SET_CONTACT_SUCCESS',

  SAVE_CONTACT_SUCCESS: 'SAVE_CONTACT_SUCCESS',
  RESET_CONTACT: 'RESET_CONTACT',
  ERROR: 'ERROR',
};

export const resetContact = () => async (dispatch) => {
  dispatch({
    type: contactTypes.RESET_CONTACT,
  });
};

export const setContact = (id, alert) => async (dispatch) => {
  try {
    const resp = await setContactByID(id, alert);
    if (resp.status === 200) {
      dispatch({
        type: contactTypes.SET_CONTACT_SUCCESS,
        payload: resp,
      });
      dispatch({
        type: 'SHOW_POPUP',
        payload: { id: 0, type: 'edit-contact-success' },
      });
    }
  } catch (error) {
    if (error.status === 400) {
      const popupError = [];
      for (const key in error.data) {
        if (error.data.hasOwnProperty(key)) {
          popupError.push(key);
        }
      }
      dispatch({
        type: 'SHOW_POPUP',
        payload: { id: 0, type: 'valida-contact-failed', data: popupError },
      });
    }
  }
};

export const getContact = (id) => async (dispatch) => {
  try {
    const contact = await getContactByID(id);
    dispatch({
      type: contactTypes.GET_CONTACT_SUCCESS,
      payload: contact,
    });
  } catch (error) {
    dispatch({
      type: 'ERROR',
      payload: { message: 'Erro ao publicar o alerta' },
    });
  }
};

export const saveContact = (alert) => async (dispatch) => {
  try {
    const newAlert = await newContact(alert);
    if (newAlert.status === 201) {
      dispatch({
        type: contactTypes.SAVE_CONTACT_SUCCESS,
        payload: newAlert,
      });
      dispatch({
        type: 'SHOW_POPUP',
        payload: { id: 0, type: 'create-contact-success' },
      });
    }
  } catch (error) {
    if (error.status === 400) {
      const popupError = [];
      for (const key in error.data) {
        if (error.data.hasOwnProperty(key)) {
          popupError.push(key);
        }
      }
      dispatch({
        type: 'SHOW_POPUP',
        payload: { id: 0, type: 'valida-contact-failed', data: popupError },
      });
    }
  }
};

export const getContactData = () => async (dispatch) => {
  try {
    const data = await getData();
    dispatch({
      type: contactTypes.GET_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: 'ERROR',
      payload: { message: 'Erro ao carregar dados' },
    });
  }
};
