import axios from 'axios';

export const isAuthenticated = () => localStorage.getItem('token') !== null;

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
  }
};
