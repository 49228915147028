import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { colors, fonts, rem } from '../../assets/styles';
import {
  searchAlerts,
  clearSearchAlerts,
  loadAlerts,
} from '../../store/alertList';

const Search = () => {
  const [search, setSearch] = useState({ value: '' });

  const dispatch = useDispatch();
  function handleSubmit() {
    if (search.value !== '') {
      dispatch(searchAlerts(1, search.value));
    }
  }

  function handleClear() {
    dispatch(clearSearchAlerts());
    dispatch(loadAlerts(1, null));
  }

  function onEnter(e) {
    e.preventDefault();
    if (e.keyCode === 13) {
      dispatch(searchAlerts(1, e.target.value));
    }
    if (e.keyCode === 8 && e.target.value === '') {
      dispatch(clearSearchAlerts());
      dispatch(loadAlerts(1, null));
    }
  }

  return (
    <Main>
      <Input
        type="search"
        value={search.value}
        placeholder="Procurar Conteúdo"
        onKeyUp={(e) => onEnter(e)}
        onChange={(e) => {
          const newSearch = { value: e.target.value };
          setSearch(newSearch);
        }}
      />
      {search.value && (
        <Clear
          onClick={() => {
            handleClear();
            setSearch({ value: '' });
          }}
        >
          Limpar
        </Clear>
      )}
      <Submit onClick={handleSubmit}>Buscar</Submit>
    </Main>
  );
};

Search.propTypes = {};

export default Search;

const Main = styled.div`
  background-color: #fff;
  position: relative;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dbdad3;
  padding: 15px 17px 22px;
  max-width: 860px;
  @media print {
    display: none;
  }
`;
const Input = styled.input`
  appearance: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #dbdad3;
  font: Italic 14px/24px Roboto;
  display: block;
  flex-grow: 1;
  font-size: ${rem(14)};
  margin: 0;
  color: #9e9c90;
  outline: none;
  padding: 17px 10px;
  &::placeholder {
    color: #dbdad3;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;
const Submit = styled.button`
  right: 22px;
  top: 30px;
  position: absolute;
  background: transparent;
  outline: none;
  border: none;
  color: ${colors.red};
  display: block;
  font-size: ${rem(12)};
  font-weight: ${fonts.sans.bold};
  flex-shrink: 0;
  margin: 0;
  padding: ${rem(12)} ${rem(8)};
  text-transform: uppercase;
`;

const Clear = styled.button`
  right: 85px;
  top: 42px;
  position: absolute;
  background: transparent;
  outline: none;
  border: none;
  color: #9e9c90;
  display: block;
  font-size: ${rem(12)};
  font-weight: ${fonts.sans.light};
  flex-shrink: 0;
  margin: 0;
  padding: 0px;
  text-transform: uppercase;
`;
