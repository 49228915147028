/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showPopUp, hidePopUp } from "../../store/popup";
import { publishAlert, unpublishAlert } from "../../store/alertList";
import {
  setActiveContact,
  setInctiveContact,
  sendDeleteContact,
} from "../../store/contactList";
import { deleteAssetStore } from "../../store/alert";
import { deleteFile } from "../../api/index";

class PopUp extends Component {
  state = {
    redirect: null,
    label: {
      // Alerts
      verticals: "Segmentação",
      title: "Titulo",
      content: "Texto",
      assets: "Link",
      tags: "Tags",
      // Contacts
      first_name: "Nome",
      last_name: "Sobrenome",
      email: "Email",
      phone: "Telefone",
      business_role: "Atividade",
      company: "Empresa",
      is_active: "Status",
    },
  };

  deleteContact = (id) => {
    this.props.sendDeleteContact(id, this.props.contactList.data);
  };

  activeContact = (id) => {
    this.props.setActiveContact(id, this.props.contactList.data);
  };

  inactiveContact = (id) => {
    this.props.setInctiveContact(id, this.props.contactList.data);
  };

  publish = (id) => {
    this.props.publishAlert(id);
  };

  unpublish = (id) => {
    this.props.unpublishAlert(id);
  };

  cancel = () => {
    this.props.hidePopUp();
  };

  toRedirect(to) {
    this.setState({ redirect: to });
  }

  render() {
    const { status, id, type, data } = this.props.popup;
    const stringSort = [
      "title",
      "content",
      "assets",
      "tags",
      "verticals",
      "first_name",
      "last_name",
      "email",
      "company",
      "is_active",
      "plan",
    ];
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        {status && (
          <Main>
            {/* ALERTS */}

            {type === "remove" && (
              <PopUpContent>
                <h4>Você realmente deseja despublicar esse Alerta?</h4>
                <Hr />
                <Buttons>
                  <button onClick={() => this.unpublish(id)} className="yes">
                    Sim
                  </button>
                  <button onClick={() => this.cancel()} className="not">
                    Não
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "publish" && (
              <PopUpContent>
                <h4>Você realmente deseja publicar esse Alerta?</h4>
                <Hr />
                <Buttons>
                  <button
                    onClick={() => {
                      this.publish(id);
                      this.props.showPopUp("", "loading");
                    }}
                    className="yes"
                  >
                    Sim
                  </button>
                  <button onClick={() => this.cancel()} className="not">
                    Não
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "valid-cancel" && (
              <PopUpContent>
                <h4>
                  Você realmente deseja Cancelar? <br />
                  Todos os dados não salvos serão perdidos!
                </h4>
                <Hr />
                <Buttons>
                  <button
                    onClick={async () => {
                      return
                      await this.props.popup.deleteFile.forEach((item) => {
                        deleteFile(item.id)
                          .then()
                          .catch((err) =>
                            window.alert(
                              "Erro ao deletar arquivo, tente novamente ou entre em contato com o time.",
                              err
                            )
                          );
                      });
                      this.toRedirect("/dashboard");
                      this.cancel();
                    }}
                    className="yes"
                  >
                    Sim
                  </button>
                  <button onClick={() => this.cancel()} className="not">
                    Não
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "create-success" && (
              <PopUpContent>
                <h4>Alerta criado com sucesso!</h4>
                {/* <img src="" /> */}
                <Hr />
                <Buttons>
                  <button
                    onClick={() => {
                      this.toRedirect("/dashboard");
                      this.cancel();
                    }}
                    className="yes"
                  >
                    Home
                  </button>
                  <button
                    onClick={() => window.location.reload()}
                    className="yes"
                  >
                    Novo Alerta
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "create-failed" && (
              <PopUpContent>
                <h4>
                  Link inválido!
                  <br />
                  Insira um ou mais links válidos separados por virgula!
                </h4>
                <Hr />
                <Buttons>
                  <button
                    onClick={() => {
                      this.cancel();
                    }}
                    className="not"
                  >
                    Fechar
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "valid-create" && (
              <PopUpContent>
                <h4>Atenção: </h4>
                <Ul>
                  {/* {data.map((e, i) => (
                    <li key={i}>{this.state.label[e]}</li>
                  ))} */}
                  {stringSort.map((e, i) => {
                    if (data.includes(e)) {
                      return (
                        <li key={i}>
                          {data.includes(e) ? this.state.label[e] : ""}
                        </li>
                      );
                    }
                    return "";
                  })}
                </Ul>
                {data.length === 1 && <h4>Deve ser preenchido!</h4>}
                {data.length > 1 && <h4>Devem ser preenchidos!</h4>}

                {/* <img src="" /> */}
                <Hr />
                <Buttons>
                  <button onClick={() => this.cancel()} className="not">
                    Fechar
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "ASSET_NOT_SAVED" && (
              <PopUpContent>
                <h4>Atenção: todos os anexos devem ser salvos. </h4>
                <Hr />
                <Buttons>
                  <button onClick={() => this.cancel()} className="not">
                    Fechar
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "edit-success" && (
              <PopUpContent>
                <h4>Alerta editado com sucesso!</h4>
                {/* <img src="" /> */}
                <Hr />
                <Buttons>
                  <button
                    onClick={() => {
                      this.toRedirect("/dashboard");
                      this.cancel();
                    }}
                    className="yes"
                  >
                    Home
                  </button>
                  <button
                    onClick={() => {
                      this.toRedirect("/alert/add");
                      this.cancel();
                    }}
                    className="yes"
                  >
                    Novo Alerta
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {/* CONTACTS */}

            {type === "valid-contact-create" && (
              <PopUpContent>
                <h4>Atenção: </h4>
                <Ul>
                  {/* {data.map((e, i) => (
                    <li key={i}>{this.state.label[e]}</li>
                  ))} */}
                  {stringSort.map((e, i) => {
                    if (data.includes(e)) {
                      return (
                        <li key={i}>
                          {data.includes(e) ? this.state.label[e] : ""}
                        </li>
                      );
                    }
                    return "";
                  })}
                </Ul>
                {data.length === 1 && <h4>Deve ser preenchido!</h4>}
                {data.length > 1 && <h4>Devem ser preenchidos!</h4>}

                {/* <img src="" /> */}
                <Hr />
                <Buttons>
                  <button onClick={() => this.cancel()} className="not">
                    Fechar
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "create-contact-success" && (
              <PopUpContent>
                <h4>Contato criado com sucesso!</h4>
                {/* <img src="" /> */}
                <Hr />
                <Buttons>
                  <button
                    onClick={() => {
                      this.toRedirect("/contact");
                      this.cancel();
                    }}
                    className="yes"
                  >
                    Contatos
                  </button>
                  <button
                    onClick={() => window.location.reload()}
                    className="yes"
                  >
                    Novo contato
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "valida-contact-failed" && (
              <PopUpContent>
                <h4>Atenção: </h4>
                <Ul>
                  {stringSort.map((e, i) => {
                    if (data.includes(e)) {
                      return (
                        <li key={i}>
                          {data.includes(e) ? this.state.label[e] : ""} inválido
                        </li>
                      );
                    }
                    return "";
                  })}
                </Ul>
                <Hr />
                <Buttons>
                  <button
                    onClick={() => {
                      this.cancel();
                    }}
                    className="not"
                  >
                    Fechar
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "edit-contact-success" && (
              <PopUpContent>
                <h4>Contato editado com sucesso!</h4>
                {/* <img src="" /> */}
                <Hr />
                <Buttons>
                  <button
                    onClick={() => {
                      this.toRedirect("/contact");
                      this.cancel();
                    }}
                    className="yes"
                  >
                    Contatos
                  </button>
                  <button
                    onClick={() => {
                      this.toRedirect("/contact/add");
                      this.cancel();
                    }}
                    className="yes"
                  >
                    Novo contato
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "confirm-contact-active" && (
              <PopUpContent>
                <h4>Você realmente deseja ativar esse Contato?</h4>
                <Hr />
                <Buttons>
                  <button
                    onClick={() => this.activeContact(id)}
                    className="yes"
                  >
                    Sim
                  </button>
                  <button onClick={() => this.cancel()} className="not">
                    Não
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "confirm-contact-inactive" && (
              <PopUpContent>
                <h4>Você realmente deseja inativar esse Contato?</h4>
                <Hr />
                <Buttons>
                  <button
                    onClick={() => this.inactiveContact(id)}
                    className="yes"
                  >
                    Sim
                  </button>
                  <button onClick={() => this.cancel()} className="not">
                    Não
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "confirm-contact-delete" && (
              <PopUpContent>
                <h4>Você realmente deseja deletar esse Contato?</h4>
                <Hr />
                <Buttons>
                  <button
                    onClick={() => this.deleteContact(id)}
                    className="yes"
                  >
                    Sim
                  </button>
                  <button onClick={() => this.cancel()} className="not">
                    Não
                  </button>
                </Buttons>
              </PopUpContent>
            )}

            {type === "loading" && (
              <PopUpContent>
                <h4>Estamos processando</h4>
                <Hr />
                <div className="loading">
                  <svg className="prefix__svg-loader" viewBox="0 0 80 80">
                    <path
                      fill="#D43B11"
                      d="M40 72C22.4 72 8 57.6 8 40S22.4 8 40 8s32 14.4 32 32c0 1.1-.9 2-2 2s-2-.9-2-2c0-15.4-12.6-28-28-28S12 24.6 12 40s12.6 28 28 28c1.1 0 2 .9 2 2s-.9 2-2 2z"
                    >
                      <animateTransform
                        attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0 40 40"
                        to="360 40 40"
                        dur="0.9s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                </div>
              </PopUpContent>
            )}

            {type === "delete_asset" && (
              <PopUpContent>
                <h4>
                  Tem certeza que deseja excluir o anexo? Essa operação não
                  poderá ser desfeita.
                </h4>
                <Hr />
                <Buttons>
                  <button
                    onClick={() => {
                      console.log('--->', this.props.popup);
                      return;
                      this.props.deleteAssetStore(
                        this.props.popup.deletedAssetFilename
                      );

                      if (this.props.popup.deletedAssetId) {
                        deleteFile(this.props.popup.deletedAssetId)
                          .then((res) => res)
                          .catch((error) => error);
                      }
                      this.props.hidePopUp();
                    }}
                    className="yes"
                  >
                    Sim
                  </button>
                  <button onClick={() => this.cancel()} className="not">
                    Não
                  </button>
                </Buttons>
              </PopUpContent>
            )}
          </Main>
        )}
      </>
    );
  }
}

PopUp.propTypes = {
  popup: PropTypes.object.isRequired,
  showPopUp: PropTypes.func.isRequired,
  publishAlert: PropTypes.func.isRequired,
  unpublishAlert: PropTypes.func.isRequired,
  setActiveContact: PropTypes.func.isRequired,
  setInctiveContact: PropTypes.func.isRequired,
  sendDeleteContact: PropTypes.func.isRequired,
  hidePopUp: PropTypes.func.isRequired,
  contactList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  alerts: state.alerts,
  contactList: state.contactList,
  alertAsset: state.alert.assets,
});

export default connect(mapStateToProps, {
  showPopUp,
  hidePopUp,
  publishAlert,
  unpublishAlert,
  setActiveContact,
  setInctiveContact,
  sendDeleteContact,
  deleteAssetStore,
})(PopUp);

const Main = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const PopUpContent = styled.div`
  width: 400px;
  min-height: 100px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #dbdad3;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    font-size: 20px;
    font-weight: normal;
    padding: 0 50px;
    text-align: center;
    margin: 30px 0 10px;
  }
  .loading {
    width: 40px;
    min-height: 60px;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  button,
  a {
    margin: auto 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 30px;
    border: none;
    font-size: 16px;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    outline: none;
    &.not {
      background-color: #f05740;
    }

    &.yes {
      background-color: #4ca845;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-left: -7px;
      margin-right: 10px;
      fill: currentColor;
    }
  }
`;

const Ul = styled.ul`
  color: #f05740;
  li {
    font-size: 20px;
    font-weight: normal;
  }
`;
const Hr = styled.hr`
  width: 100%;
  border: none;
  border-top: solid 1px #dbdad3;
  margin: 18px 0;
`;
