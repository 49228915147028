import React from 'react';

const Editar = () => (
  <svg width={20} height={20} viewBox="0 0 20 20">
    <defs>
      <circle id="prefix__a" cx={9} cy={13} r={2} />
      <circle id="prefix__b" cx={16} cy={5} r={2} />
      <circle id="prefix__c" cx={2} cy={8} r={2} />
      <mask
        id="prefix__d"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x={0}
        y={0}
        width={4}
        height={4}
        fill="#fff"
      >
        <use xlinkHref="#prefix__a" />
      </mask>
      <mask
        id="prefix__e"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x={0}
        y={0}
        width={4}
        height={4}
        fill="#fff"
      >
        <use xlinkHref="#prefix__b" />
      </mask>
      <mask
        id="prefix__f"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x={0}
        y={0}
        width={4}
        height={4}
        fill="#fff"
      >
        <use xlinkHref="#prefix__c" />
      </mask>
    </defs>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <path
        d="M2 .5v6M2 9.5v8M9 .5v11M9 14.5v3M16 .5v3M16 6.5v11"
        stroke="#36434D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <use
        stroke="#35424C"
        mask="url(#prefix__d)"
        strokeWidth={2}
        strokeLinecap="square"
        xlinkHref="#prefix__a"
      />
      <use
        stroke="#35424C"
        mask="url(#prefix__e)"
        strokeWidth={2}
        strokeLinecap="square"
        xlinkHref="#prefix__b"
      />
      <use
        stroke="#35424C"
        mask="url(#prefix__f)"
        strokeWidth={2}
        strokeLinecap="square"
        xlinkHref="#prefix__c"
      />
    </g>
  </svg>
);

export default Editar;
