import { popUpTypes } from ".";

const initialState = {
  status: null,
  id: null,
  type: null,
  data: null,
  deletedAssetId: null,
  deleteFile: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case popUpTypes.SHOW:
      return {
        ...state,
        status: true,
        id: payload.id,
        type: payload.type,
        data: payload.data,
        deletedAssetFilename: payload?.asset?.filename ?? null,
        deletedAssetId: payload?.asset?.id ?? null,
        deleteFile: payload?.deleteFile ?? null,
      };
    case popUpTypes.HIDE:
      return {
        ...state,
        status: null,
        id: null,
        type: null,
      };

    default:
      return state;
  }
}
