import { combineReducers } from 'redux';
import alert from './alert/reducer';
import alerts from './alertList/reducer';
import contact from './contact/reducer';
import contactList from './contactList/reducer';
import loading from './loading/reducer';
import popup from './popup/reducer';
import auth from './login/reducer';
import plan from './plan/reducer';
import vertical from './vertical/reducer'

export default combineReducers({
  auth,
  popup,
  loading,
  alert,
  alerts,
  contact,
  contactList,
  plan,
  vertical,
});
