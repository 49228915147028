import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "../layout/Header";
import { logout } from "../../store/login";
import PopUp from "../layout/PopUp";
import Form from "./FormAlert";
import styled from "styled-components";
import { loadVerticals } from '../../store/vertical';
import { useDispatch } from "react-redux";

const AlertCreate = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadVerticals());
  }, []);

  const alert = {
    verticals: props.verticals.data,
    verticalsOptions: props.verticals.data,
    title: "",
    content: "",
    assets: "",
    tags: "",
    custom_delivery: false
  };

  return (
    <Main>
      <Header />
      <Form alert={alert} user={props.user} editMode={false} id={0} />
      <PopUp />
    </Main>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  verticals: state.vertical
});

export default connect(mapStateToProps, {
  logout,
})(AlertCreate);

const Main = styled.div``;
