/* eslint-disable import/no-anonymous-default-export */
import { Types } from ".";

const initialState = {
  data: [],
  error: null,
  loading: false,
  showContainer: false,
  btnSaveEnable: true,
  assets: [],
  preCommit: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.SAVE_ALERT:
      return payload;

    case Types.RESET_ALERT:
      return {
        ...state,
        data: [],
        loading: false,
      };

    case Types.GET_ALERT:
      return {
        ...state,
        data: [],
        loading: true,
      };

    case Types.GET_ALERT_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        assets: payload.assets.map(item => {
          item.id_file = item.id;
          return item
        }),
        preCommit: payload.assets
      };

    case Types.SET_ALERT:
      return {
        ...state,
        data: [],
        loading: true,
      };

    case Types.SET_ALERT_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };

    case Types.REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);

    case Types.ERROR:
      return {
        ...state,
        // data: [],
        loading: false,
      };

    case "ALERTS_ASSETS_ADD":
      const output = {
        filename: payload.filename,
        tag: null,
        id: payload.id,
        showTagInput: false,
        file: {
          name: payload.file.name,
          size: payload.file.size,
          type: payload.file.type,
        },
      };

      return {
        ...state,
        showContainer: true,
        assets: [...state.assets, output],
      };

    case "ALERTS_ASSETS_REMOVE":
      return {
        ...state,
        assets: state.assets.filter((asset) => asset.filename !== payload),
      };

    case "ALERTS_ASSETS_UPDATE":
      return {
        ...state,
        assets: state.assets.map((asset, index) => {
          if (asset.file === payload.file) {
            return {
              ...asset,
              isSaved: true,
            };
          } else {
            return asset;
          }
        }),
      };

    case Types.ALERTS_ASSETS_UPDATE_UI:
      return {
        ...state,
        assets: state.assets.map((asset, index) => {
          if (asset.filename === payload) {
            return {
              ...asset,
              showTagInput: !asset.showTagInput,
            };
          } else {
            return asset;
          }
        }),
        preCommit: state.preCommit.map(file => {
          if (file.id === payload.id) {
            return {
              ...file,
              showTagInput: !file?.showTagInput
            }
          } else {
            return file;
          }
        })
      };

    case Types.ALERTS_ASSETS_UPDATE_SHORTURL:
      return {
        ...state,
        preCommit: state.preCommit.map(file => {
          if (file.id === payload.id) {
            return {
              ...file,
              id_file: payload.data.id,
              identifier: payload.data.identifier,
              short_url: payload.data.short_url,
              tag_id: payload.data.tag,
              type: payload.data.type,
              isUploaded: true
            }
          } else {
            return file;
          }
        })
      };

    case Types.ALERTS_ASSETS_UPDATE_TAG:
      return {
        ...state,
        assets: state.assets.map((asset, index) => {
          if (asset.filename === payload.filename) {
            return {
              ...asset,
              tag: payload.tag,
            };
          } else {
            return asset;
          }
        }),
        preCommit: state.preCommit.map(file => {
          if (file.id === payload.id) {
            return {
              ...file,
              tag: payload.tag
            }
          }
          else return file
        })
      };

    case Types.ADD_PRE_COMMIT_FILES:
      const file = {
        type: payload.type,
        name: payload.name,
        size: payload.size,
        isUploaded: payload.isUploaded,
        id: payload.id,
        isNew: payload.isNew
      }
      return {
        ...state,
        preCommit: [...state.preCommit, file]
      }

    case Types.REMOVE_PRE_COMMIT_FILES:
      const id_temp = payload.id ? payload.id : payload

      return {
        ...state,
        preCommit: [...state.preCommit.filter(item => item.id !== id_temp)]
      }

    case Types.CLEAR_REDUX:
      return {
        ...state,
        preCommit: [],
        data: {}
      }

    case "ALERT_CLEAN":
      return {
        ...state,
        assets: [],
      };


    case Types.UPDATE_MAIN_ATTACH:
      return {
        ...state,
        preCommit: [
          ...state.preCommit.map(item => item.id === payload.value
            ? { ...item, leading: true }
            : { ...item, leading: false }
          )]
      }

    case Types.UPDATE_MAIN_TEXT:
      return {
        ...state,
        preCommit: [
          ...state.preCommit.map(item => item.leading === true
            ? { ...item, label: payload }
            : item
          )]
      }

    case Types.BTN_SAVE_TOOGLE:
      return {
        ...state,
        btnSaveEnable: payload
      }

    default:
      return state;
  }
}
