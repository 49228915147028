import { Types } from '.';

const initialState = {
  data: [],
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.LOAD:
      return {
        ...state,
        data: [],
        loading: true,
      };

    case Types.LOAD_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };

    case Types.ERROR:
      return {
        ...state,
        data: [],
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
