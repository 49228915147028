import axios from "axios";
// import * as logger from 'axios-logger';
import { merge } from "lodash";
import environment from "../config/environment";

export const { cmsURL } = environment.cmsApi;
export const { backofficeURL } = environment.backofficeApi;

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

if (environment.env === "development") {
  // instance.interceptors.request.use(logger.requestLogger, logger.errorLogger);
  // instance.interceptors.response.use(logger.responseLogger, logger.errorLogger);
}

instance.interceptors.request.use((config) => {
  const { token } = localStorage;
  if (token) {
    const headers = merge(
      {
        Authorization: `Bearer ${token}`,
      },
      config.headers
    );
    config.headers = headers;
  }
  return config;
});

export default instance;
