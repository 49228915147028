export const EDITOR_CONFIG = {
    height: 500,
    menubar: false,
    selector: "textarea#content",
    content_style:
        "p { font-family: 'Arial'; font-size: 18.5px; font-weight: 100; color: #444 }",
    plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
        "undo redo | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ",
}

export const PUBLISHED = "published"
export const CONFIRM = "confirm"
export const NOTHING = "nothing"
export const POST = "post"
export const PATCH = "patch"
export const WAIT_FOR_SAVE = `<div style="display:flex; flex-direction: column; align-items: center;"><img src="/loading.gif"/><div>Por favor aguarde</div></div>`
export const DASHBOARD = "/dashboard"