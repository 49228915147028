import { getVerticals } from '../../api';

export const Types = {
  LOAD: 'LOAD_VERTICALS',
  LOAD_SUCCESS: 'LOAD_VERTICALS_SUCCESS',
  LOAD_FAILED: 'LOAD_VERTICALS_FAILED',
}


export const loadVerticals = () => async (dispatch) => {
  try {
    const verticals = await getVerticals();

    const verticalsList = verticals.map((vertical) => {
      return {
        value: vertical.id,  label:vertical.name
      }
    })
    dispatch({
      type: Types.LOAD_SUCCESS,
      payload: verticalsList,
    });
  } catch (error) {
    const msg = 'Erro ao carregar as verticais!';

    dispatch({
      type: Types.LOAD_FAILED,
      payload: { message: msg },
    });
  }
};
