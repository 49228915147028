const environment = {
  env: process.env.NODE_ENV || 'development',
  appName: process.env.REACT_APP_NAME,
  cmsApi: {
    cmsURL: process.env.REACT_APP_CMS_API_BASE_URL,
  },
  backofficeApi: {
    backofficeURL: process.env.REACT_APP_BACKOFFICE_API_BASE_URL,
  }
};

export default environment;
