export default {
  black: '#000',
  red: '#f05741',
  redDark: '#c6352e',
  blue: '#39c',
  yellow: '#fbc744',
  yellowDark: '#f6881c',
  green: '#3fa674',
  greenLight: '#8cc63f',
  greyDarker: '#1e1e1e',
  greyDark: '#232323',
  grey: '#4d4d4d',
  greyLight: '#9e9c90',
  greyLighter: '#dbdad3',
  ice: '#f5f5f4',
  white: '#fff',
};
