import React from 'react';

function IconLink() {
  return (
    <svg width={24} height={23.999} viewBox="0 0 24 23.999">
      <path
        d="M13.723 18.654l-3.61 3.609a5.924 5.924 0 01-8.379-8.377L6.6 9.021a5.888 5.888 0 019.466 1.527l-1.593 1.592a3.829 3.829 0 00-6.4-1.653L3.2 15.354a3.847 3.847 0 005.442 5.44l2.425-2.424a7.482 7.482 0 002.656.284zm.161-16.918L10.31 5.312a7.569 7.569 0 012.653.283l2.393-2.389A3.848 3.848 0 1120.8 8.647l-4.831 4.831a3.857 3.857 0 01-5.439 0 3.767 3.767 0 01-.956-1.643l-.084.068-1.524 1.515a5.885 5.885 0 009.466 1.528l4.832-4.831a5.925 5.925 0 00-8.38-8.379z"
        fill="#9e9c90"
      />
    </svg>
  );
}

export default IconLink;
