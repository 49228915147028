import { Amplify, Auth } from 'aws-amplify';
import http, { backofficeURL } from '../../api/http.service';
import { setAuthToken } from '../../api/auth';

const awsconfig = {
  region: process.env.REACT_APP_AWS_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
  authenticationFlowType: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_AUTH_FLOW_TYPE,
};

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

export const Types = {
  USER_LOADED: 'USER_LOADED',
  AUTH_ERROR: 'AUTH_ERROR',
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_ROLE: 'USER_ROLE',
  NOT_AUTH: 'NOT_AUTH',
};

// Logout
export const logout = () => (dispatch) => {
  dispatch({ type: Types.USER_LOGOUT });
};

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await http.get(`${backofficeURL}/auth/users/profiles/me/`);
    const { role } = res.data;

    res.data['is_admin'] = role.filter(role => ['administrator', 'editor'].includes(role)).length > 0

    if (res.data['is_admin']) {
      dispatch({
        type: Types.USER_LOADED,
        payload: res.data,
      });
    } else {
      localStorage.removeItem('token');
      dispatch({
        type: Types.NOT_AUTH,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.AUTH_ERROR,
    });
  }
};

/**
 * Login via Amplify
 * AWS Cognito
 */
export const login = (email, password) => async (dispatch) => {
  localStorage.removeItem('token');

  Auth.signIn(email, password)
    .then((response) => {
      const data = {
        token: response.signInUserSession.idToken.jwtToken,
        profile: {
          displayName: response.attributes.name,
          email: response.attributes.email,
          firstName: response.attributes.given_name,
          lastName: response.attributes.family_name,
          username: response.attributes.sub,
        },
      };

      dispatch({
        type: Types.USER_LOGIN,
        payload: data,
      });
      dispatch(loadUser());
    })
    .catch((error) => {
      console.log('ERROR', error)
      dispatch({
        type: Types.AUTH_ERROR,
        payload: error,
      });
    });
};
