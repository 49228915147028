import React from "react";

function TagIcon(props) {
  const fill = props.filled ? "white" : "black";
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2734 6.60665L7.38671 0.71998C7.14004 0.473313 6.80004 0.333313 6.44671 0.333313H1.66671C0.933374 0.333313 0.333374 0.933313 0.333374 1.66665V6.44665C0.333374 6.79998 0.473374 7.13998 0.726707 7.38665L6.61337 13.2733C7.13337 13.7933 7.98004 13.7933 8.50004 13.2733L13.28 8.49331C13.8 7.97331 13.8 7.13331 13.2734 6.60665ZM3.33337 4.33331C2.78004 4.33331 2.33337 3.88665 2.33337 3.33331C2.33337 2.77998 2.78004 2.33331 3.33337 2.33331C3.88671 2.33331 4.33337 2.77998 4.33337 3.33331C4.33337 3.88665 3.88671 4.33331 3.33337 4.33331Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default TagIcon;
